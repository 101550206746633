// ==============
// Menu component
// ==============

.Menu {
  z-index: 20;
  position: absolute;
  width: 100%;
  top: 100% !important;
  left: 0 !important;
  background-color: $color-white;
  color: $color-dark;
  box-shadow: 0 16px 16px 1px rgba($color-dark, .075);

  &[aria-hidden="false"] .Wrapper {
    overflow-x: hidden;
    overflow-y: auto;

    @media($mq-phone) {
      height: calc(100vh - 72px);
    }
    @media($mq-tablet) {
      max-height: calc(100vh - 72px);
    }
    @media($mq-desktop) {
      max-height: calc(100vh - 124px);
    }
  }
  .Wrapper {
    display: none;
  }
}


// -----------
// Menu levels
// -----------

.Menu-levels {
  position: relative;

  @media($mq-tablet) {
    margin: 0 -25px;
  }
  @media($mq-desktop) {
    margin: 0 -50px;
  }
}

// Level 0
.Menu-level--0 {
  width: 100%;
  padding: 3px 0 25px;

  @media($mq-tablet) {
    width: calc(100% / 2);
    padding: 3px 25px 25px;
  }
  @media($mq-desktop) {
    width: calc(100% / 3);
    padding: 3px 50px 25px;
  }

  // Drilldown effect
  .is-drilldown {
    position: initial !important;
    max-width: none !important;
    min-height: 0 !important;
  }
}

// Level 1
.Menu-level--1 {
  @media($mq-phone) {
    width: 100%;
    padding: 20px;
    background-color: $color-white;
  }
  @media($mq-tablet) {
    width: 50%;
    padding: 25px;
  }
  @media($mq-desktop) {
    width: calc(100% / 3 * 2);
    padding: 25px 50px;
  }
  @media($mq-from-tablet) {
    z-index: 1;
    visibility: hidden;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    opacity: 0;
    overflow-x: hidden;
    overflow-y: auto;
    transition: opacity .25s ease-in-out,
                visibility .25s ease-in-out;

    // First level open by default
    .Menu-item.is-active & {
      z-index: 0 !important;
      display: block !important;
      top: 0;
      left: auto !important;
      right: 0 !important;
      visibility: visible;
      opacity: 1;
    }

    // Dropdown effect
    &.js-dropdown-active {
      visibility: visible;
      opacity: 1;
    }
  }

  .is-submenu-item {
    display: flex;

    @media($mq-upto-tablet) {
      flex-direction: column;
    }
  }
}

// Level 1 - GoBack button
.Menu-goBack {
  a {
    display: block;
    padding: 15px 47px 12px;
    cursor: pointer;
    @include text-underline($color: $color-red);

    &:hover, #{$keyboard} &:focus {
      & ~ .icon {
        color: $color-red;
      }
    }
  }

  .icon {
    position: absolute;
    top: 36px;
    left: 30px;
    transform: rotate(180deg);
    transition: color .25s ease-in-out;
  }
}

// Level 2
.Menu-level--2 {
  margin-bottom: 22px;
  font-size: rem-calc(17);

  @media($mq-from-tablet) {
    font-size: rem-calc(16);
  }
}


// ------------
// Menu section
// ------------

.Menu-section {
  padding: 22px 0;

  &:not(:last-child) {
    border-bottom: 1px solid $color-dark;
  }

  // Drilldown effect
  .is-drilldown {
    position: relative;
    overflow: hidden;
  }
}

.Menu-sectionTitle {
  margin-bottom: 10px;
  font-size: rem-calc(18);
  @include font-bold($font-family: $font-family-title, $text-transform: uppercase);
}

.Menu-sectionLink {
  position: relative;
  font-size: rem-calc(19);
}


// ----------
// Menu items
// ----------

.Menu-items {
  font-size: rem-calc(19);

  & > .Menu-item {

    a:hover, a:focus {
      color: $color-red;
      text-decoration: underline;
    }

    a.Button--primary:hover, a.Button--primary:focus {
      text-decoration: inherit;
      &:after {
        background-color: $color-red;
      }
    }

    // From tablet
    @media($mq-from-tablet) {
      &.is-active > a {
        color: $color-red;
      }
    }

    & > a {
      padding: 10px 0;
      cursor: pointer;
    }
    @media($mq-from-tablet) {
      &.is-active > a {
        @include font-medium;
        cursor: text;
        transition: none;
      }
    }

    // Dropdown effect
    &.opens-inner > .is-dropdown-submenu {
      top: 0;
      left: auto;
    }

    &.opens-right > .is-dropdown-submenu {
      right: auto;
      left: 100%;
    }
  }

  // Drilldown effect
  .is-drilldown-submenu {
    z-index: -1;
    position: absolute;
    width: calc(100% + 40px);
    height: 100%;
    top: 0;
    left: calc(100% + 20px);
    transition: transform .25s ease-in-out;

    &.is-active {
      z-index: 1;
      display: block;
      transform: translateX(-100%);
    }
    &.is-closing {
      transform: translateX(100%);
    }
  }
}

.Menu-item {
  & > a {
    position: relative;
    display: block;
    width: 100%;
    @include text-underline($color: $color-red);

    // Icons
    .icon {
      position: absolute;
      top: 10px;
      right: 6px;
    }
  }
  & > a, & > p {
    .Menu-level--1 & {
      padding: 15px 0 12px;
    }
    .Menu-level--2 & {
      padding: 0 0 5px;

      @media($mq-from-tablet) {
        padding: 0 0 2px;
      }
    }
  }
}

.Menu-subItems {
  width: 100%;
  margin-top: -5px;

  @media($mq-upto-tablet) {
    order: 1;
    padding-top: 20px;
  }
  @media($mq-desktop) {
    margin-right: 50px;
  }

  & > ul > .Menu-item:not(:last-child) {
    border-bottom: 1px solid $color-dark;
  }
}

.Menu-advert {
  width: 100%;
  height: 100%;
  //padding: 40px 0 10px;
  padding-top: 10px;

  @media($mq-phone) {
    //display: none;
    display: flex;
    justify-content: center;

    img:last-child {
      max-width: 500px;
      width: 100%;
    }
  }
  @media($mq-desktop) {
    margin-left: 50px;
    padding: 10px 0;
  }

  // Desktop
  img:first-child {
    height: 100%;
    object-fit: cover;

    @media($mq-upto-tablet) {
      display: none;
    }
  }
  // Tablet-phone
  img:last-child {
    height: 100%;
    object-fit: cover;

    @media($mq-desktop) {
      display: none;
    }
  }
}

.Menu-advert, .Menu-subItems {
  @media($mq-from-tablet) {
    flex: 1;
  }
}


// --------------------
// Inspire me & My list
// Up to tablet only
// --------------------

//.Menu-item--uptoTablet {
//  @media($mq-desktop) {
//    display: none;
//  }
//}
//
//.Menu-itemSeparator hr {
//  margin-top: 0;
//  margin-bottom: 22px;
//  padding-bottom: 22px;
//  border: 0;
//  border-bottom: 1px solid $color-dark;
//}


// ----------
// Newsletter
// ----------

.Menu-newsletter{
  padding-bottom:0;
}

.Menu-newsletterContent {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;

  p {
    font-size: rem-calc(16);
    line-height: 1.2;
  }
  a {
    color: $color-dark;
    text-decoration:underline;
    transition: color .25s ease-in-out;

    &:hover, #{$keyboard} &:focus {
      color: $color-red;
    }

    .icon {
      width: 20px;
      height: 20px;
    }
  }
}

// Menu-newsletter iframe

.Menu-newsletter{

  padding-top:0;

  iframe{

    width:100%;
    border:none;

    @media($mq-phone) {
      min-height: 260px;
    }

    @media($mq-from-tablet) {
      min-height: 235px;

    }

  }
}
