// =============
// Section style
// =============

.Section {
  background-color: $color-white;

  &:not([class^="Homepage"]) {
    @extend .Section--bottomStroke;
    @extend .Section--hoverGreen;

    &:nth-of-type(2n+1) {
      @extend .Section--lightgreige;

      .Book-pressButton:not(.Truncate-unexpandButton) {
        background: rgb(248, 246, 241);
      }

      .Title.Title--primary {
        color: $color-darkGreen;
      }
    }
    &:nth-of-type(2n) {
      @extend .Section--greige;

      .Book-pressButton:not(.Truncate-unexpandButton) {
        background: rgb(228, 219, 208);
      }
    }
  }

  &[class*="Footer"] {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      top: 314px;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }

    & > * {
      z-index: 1;
    }
  }

  // Color variants
  &--lightgreige {
    --bg-color: 248, 246, 241;
    background-color: rgb(var(--bg-color));
  }
  &--greige {
    --bg-color: 228, 219, 208;
    background-color: rgb(var(--bg-color));
  }

  // Color override
  &--lightgreigeOverride {
    background-color: $color-lightGreige !important;
  }
  &--greigeOverride {
    background-color: $color-greige !important;
  }

  // Hover color variants
  &--hoverRed {
    --section-color: #EF4B53;

    .Section-button {
      @extend .Button--red;
    }
  }
  &--hoverGreen {
    --section-color: #0EB79E;

    .Section-button {
      @extend .Button--green;
    }
  }

  // Footer color variants
  &--lightblueFooter::before {
    background-color: $color-lightPrimary;
  }
  &--lightgreigeFooter::before {
    background-color: $color-lightGreige;
  }
  &--whiteFooter::before {
    background-color: $color-white;
  }

  // Stroke variants
  &--topStroke {
    border-top: 2px solid $color-blue;
  }
  &--bottomStroke {
    border-bottom: 2px solid $color-dark;
  }

  // Declination
  @supports (background-color: var(--section-color)) {
    .Slider-bullets .Slider-bullet--active::after {
      background-color: var(--section-color);
    }
    @media($mq-phone) {
      .CirclesList-item:focus .CirclesList-image {
        background-color: var(--section-color);
      }
    }
    @media($mq-from-tablet) {
      .CirclesList-item:hover .CirclesList-image,
      #{$keyboard} .CirclesList-item:focus .CirclesList-image {
        background-color: var(--section-color);
      }
    }
  }
  @supports (border-color: var(--section-color)) {
    .Title span::after, .Title a::after {
      border-color: var(--section-color);
    }
    @media($mq-phone) {
      .NewsItem:focus::after,
      .CirclesList-item:focus .CirclesList-image::after {
        border-color: var(--section-color);
      }
    }
    @media($mq-from-tablet) {
      .NewsItem:hover::after, #{$keyboard} .NewsItem:focus::after,
      .CirclesList-item:hover .CirclesList-image::after,
      #{$keyboard} .CirclesList-item:focus .CirclesList-image::after {
        border-color: var(--section-color);
      }
    }
  }
  @supports (color: var(--section-color)) {
    .BookAttachment-button[aria-disabled="false"] .icon,
    .Slider-button[aria-disabled="false"] .icon,
    .NewsItem .icon {
      color: var(--section-color);
    }
    @media($mq-phone) {
      .BookItem-container:focus .BookItem-title a, .BookItem-title a:focus,
      .TipsItem-title:focus, a.TipsItem-contributor:focus .CirclesList-item a:focus, .NewsItem:focus {
        color: var(--section-color);
      }
    }
    @media($mq-from-tablet) {
      .BookItem-container:hover .BookItem-title a, #{$keyboard} .BookItem-container:focus .BookItem-title a,
      .BookItem-title a:hover, #{$keyboard} .BookItem-title a:focus,
      .TipsItem-title:hover, #{$keyboard} .TipsItem-title:focus,
      a.TipsItem-contributor:hover, #{$keyboard} a.TipsItem-contributor:focus,
      .CirclesList-item a:hover, #{$keyboard} .CirclesList-item a:focus,
      .NewsItem:hover, #{$keyboard} .NewsItem:focus {
        color: var(--section-color);
      }
    }
  }
  @supports (background-color: var(--bg-color)) {
    .Book-pressButton {
      &:not(.Truncate-unexpandButton)::before {
        background: linear-gradient(to bottom, rgba(var(--bg-color), 0), rgb(var(--bg-color)));
      }
    }
  }


}

.Section .Wrapper  {
  padding-top: 35px;
  padding-bottom: 35px;

  @media($mq-from-tablet) {
    padding-top: 70px;
    padding-bottom: 45px;
  }
}

.Section .Hero .Wrapper {
  padding-top: 0;
  padding-bottom: 0;
}

