// ==============
// News component
// ==============

// ------
// Header
// ------

.News-header {
  color: $color-dark;
}

.News-type {
  @include font-title($text-transform: uppercase);
}

.News-date {
  color: $color-darkGray;
  @include font-title;
  font-size: rem-calc(15);
}

.News-title {
  @include font-extrabold($font-family: $font-family-title);
}

.News-subtitle {
  @include font-title;
}

.News-intro {
  @include font-title;

  p {
    @include font-title;
  }
}

.News .News-main.Section .Wrapper {
  padding-top: 35px;
  padding-bottom: 35px;

  @media($mq-from-tablet) {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}
