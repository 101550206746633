// ================
// Books list style
// ================

.BooksList {
  z-index: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  @media($mq-phone) {
    flex-direction: column;
    justify-content: center;
  }
  @media($mq-from-tablet) {
    margin: 0 -22px;
  }

  @media print {
    display: table;
    margin: 0 1px;
  }


  li {
    margin: 0 auto;

    &:before {
      content: '';
      z-index: -1;
      position: absolute;
      top: 12px;
      left: -22px;
      right: -22px;
      bottom: 32px;
      border: 3px solid transparent;
      transition: background-color .25s ease-in-out,
      border-color .25s ease-in-out;
    }
    &.labeled:before {
      top: -32px;
    }

    @media($mq-from-tablet) {
      margin: 0 22px;
    }

    @media print {
      display: inline-table;
      vertical-align: top;
      margin: 0 -1px;
    }

  }

  //// Color variant
  //&--gray {
  //  & > li:last-child::after {
  //    background-color: $color-lightenGray;
  //  }
  //  & > li {
  //    @include gray-footer(205px);
  //  }
  //  & ~ .BooksList-button::before {
  //    content: '';
  //    z-index: 0;
  //    position: absolute;
  //    width: 200vw;
  //    height: 100%;
  //    background-color: $color-lightenGray;
  //    top: 0;
  //    left: -50vw;
  //  }
  //  & ~ .BooksList-button {
  //    margin-bottom: -45px;
  //    padding-bottom: 80px;
  //  }
  //}
}

.BooksList > li {
  position: relative;
  padding-top: 20px;
  padding-bottom: 30px;

  // Hide the default line for SearchResults-show
  &.SearchResults-show::after {
    top: -1px;
    bottom: auto;
    background-color: $color-white;
  }
}

.BooksList-button {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 20px 0 40px;

  .Button {
    z-index: 1;
  }
}

.BooksList {
  margin-top: 40px;

  li {
    padding-top: 0;
  }
}

