// ================
// Header component
// ================

.Header {
  position: relative;

  // Hover effects
  &::after {
    content: '';
    z-index: 30;
    position: absolute;
    width: 100%;
    height: 0;
    top: 100%;
    transition: height .25s ease-in-out,
                top .25s ease-in-out,
                background-color .25s ease-in-out;
  }
  &[class*="--hover"]::after,
  &[class*="--active"]::after {
    height: 10px;
    top: calc(100% - 5px);
  }
  &--hoverLink1::after {
    background-color: $color-redClear;
  }
  &--hoverLink2::after {
    background-color: $color-green;
  }
  &--hoverLink3::after {
    background-color: $color-yellow;
  }
  &--hoverLink4::after {
    background-color: $color-white;
  }

  // Breadcrumbs
  &[class*="--hover"] ~ main .Breadcrumbs,
  &[class*="--active"] ~ main .Breadcrumbs {
    opacity: 0;
    transition: opacity .25s ease-in-out 0s;
  }
}

.Header-main {
  padding: 10px 0;
  color: $color-white;
  background-color: $color-blue;

  @media('min-width: 700px') and ('max-width: 1200px') {
    padding: 16px 0;
  }
  @media('min-width: 1200px') {
    padding: 25px 0;
  }

  @media ($mq-phone) {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100000;
    background-color: #313D4F;
  }

  .Wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

// Logo
.Header-logo {
  margin-left: -20px;

  @media($mq-upto-tablet) {
    margin-top: -1px;
  }

  a {
    display: inline-block;
    padding: 10px 20px;

    @media('min-width: 1200px') {
      padding: 15px 20px;
    }
  }

  .icon-logo {
    width: 156px;
    height: 32px;

    @media('min-width: 1200px') {
      width: 210px;
      height: 43px;
    }
  }
}

// Menu burger
.Header-menuBurger {
  position: relative;
  width: 71px;
  height: 50px;
  padding: 11px 18px;
  transition: transform .25s ease-in-out;
  cursor: pointer;

  @media($mq-from-tablet) {
    margin-right: 20px;
  }

  &:hover, &[aria-expanded="true"] {
    transform: scale(1.2);
  }

  span {
    position: absolute;
    display: block;
    width: calc(100% - 36px);
    height: 2px;
    margin: 10px 0;
    left: 18px;
    background: $color-white;
    border-radius: 5px;
    opacity: 1;
    transform: rotate(0);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2), &:nth-child(3) {
      top: 13px;
    }
    &:nth-child(4) {
      top: 26px;
    }
  }
  &[aria-expanded="true"] span {
    &:nth-child(1) {
      width: 0;
      top: 18px;
      left: 50%;
    }
    &:nth-child(2) {
      transform: rotate(45deg);
    }
    &:nth-child(3) {
      transform: rotate(-45deg);
    }
    &:nth-child(4) {
      width: 0;
      top: 18px;
      left: 50%;
    }
  }
}

// Links
.Header-links {
  @media('max-width: 1200px') {
    display: none;
  }

  ul {
    display: flex;
    align-items: center;

    a, button {
      display: inline-block;
      margin: 0 7px;
      padding: 16px 12px 15px;
      font-size: rem-calc(16);
      @include font-medium;
      @include text-underline;

      &[data-color='red'] {
        color: $color-redClear;
      }
      &[data-color='green'] {
        color: $color-green;
      }
      &[data-color='yellow'] {
        color: $color-yellow;
      }
      &[data-color='dark-green'] {
        color: $color-darkGreen;
      }
    }
  }
}

// Search
.Header-search {
  flex: 1;
}

.Header-searchContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  &--fromTablet {
    @media($mq-phone) {
      display: none;
    }
  }
  &--phone {
    @media($mq-from-tablet) {
      display: none;
    }
  }

  form {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }

  label {
    display: flex;
    align-items: center;
    padding: 10px 25px;
    max-width: 280px;
    width: 100%;
    transition: max-width .25s ease-in-out;

    .Header--activeSearch & {
      max-width: 500px;
    }

    @media($mq-tablet) {
      margin-right: -12px;
      padding: 10px 0 10px 25px;
    }
  }

  input {
    @include font-medium;
    width: 100%;
    margin: 0 12px;
    padding: 4px 0;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid $color-white;
    color: $color-white;
    font-size: rem-calc(14);
    transition: border-color .25s ease-in-out;

    &::placeholder {
      color: $color-white;
      font-weight: 400;
      opacity: 1;
    }
  }

  .icon {
    width: 25px;
    height: 25px;
    min-width: 25px;
  }
}

.Header-searchButton {
  position: relative;
  margin-right: -14px;
  padding: 11px 14px;
  transition: transform .25s ease-in-out;

  &:hover, &[aria-expanded="true"] {
    transform: scale(1.2);
  }

  .icon {
    width: 30px;
    height: 30px;
    min-width: 30px;
    transition: opacity .25s ease-in-out,
                transform .25s ease-in-out;
  }
  &[aria-expanded="true"] .icon {
    opacity: 0;
    transform: scale(0);
  }

  &:hover, &:focus {
    outline: 0;
  }
}

.Header-searchCloseButton {
  position: absolute;
  width: 32px;
  height: 30px;
  top: 11px;
  left: 14px;
  opacity: 0;
  transform: scale(0);
  transition: opacity .25s ease-in-out,
  transform .25s ease-in-out;

  [aria-expanded="true"] & {
    opacity: 1;
    transform: scale(1);
  }

  span {
    position: absolute;
    display: block;
    width: 36px;
    height: 2px;
    margin: 10px 0;
    top: 4px;
    left: -2px;
    background: $color-white;
    border-radius: 5px;

    &:first-child {
      transform: rotate(45deg);
    }
    &:last-child {
      transform: rotate(-45deg);
    }
  }
}
