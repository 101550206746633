// =========================
// Select Dropdown component
// =========================

.SelectDropdown {
  position: relative;
}

.SelectDropdown-button {
  position: relative;
  width: 100%;
  padding: 11px 11px 9px 12px;
  color: $color-dark;
  border: 1px solid $color-gray;
  border-radius: 3px;
  @include font-title;
  font-size: rem-calc(13.3);
  transition: background-color .25s ease-in-out,
              border-color .25s ease-in-out;

  &:hover, #{$keyboard} &:focus {
    background-color: $color-white;
    border-color: $color-dark;
  }

  &[aria-expanded="true"] {
    background-color: $color-dark;
    border-color: $color-dark;
    color: $color-white;
  }

  &.alignLeft {
    text-align: left;
  }

  .icon {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 13px;
    right: 11px;
    transform: rotate(-90deg);
  }
}

.SelectDropdown-pane > * {
  display: none;
  z-index: 10;
  position: absolute;
  width: 100%;
  padding: 7px 0;
  top: -1px;
  background-color: $color-white;
  border: 1px solid $color-gray;
  border-top-color: $color-dark;
  border-radius: 3px;
  font-size: rem-calc(14);
  overflow: auto;
  transition: border-color .25s ease-in-out;

  [aria-expanded="true"] ~ & {
    border-color: $color-dark;
  }
}


// ----------
// List style
// ----------

.SelectDropdown-paneList {
  max-height: 180px;

  & > * {
    max-height: 205px;
  }

  li a {
    display: block;
    padding: 6px 20px 5px;
  }
}


// ----------
// Grid style
// ----------

.SelectDropdown-paneGrid ul {
  display: flex;
  flex-wrap: wrap;
  padding-top: 3px;
  padding-left: 3px;

  li {
    width: calc(100% / 3);
    padding: 5px 0;

    &.large {
      width: 100%;
    }

    a {
      padding: 6px 15px 5px;

      &.active,
      &:hover, #{$keyboard} &:focus {
        @include font-bold;
      }
    }
  }
}
