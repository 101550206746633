// ===============
// Homepage Slider
// ===============

.HomepageSlider {
  margin-bottom: -10px;

  &.Section--bottomStroke {
    @media('max-width: 900px') {
      border-bottom: none;
    }
  }

  &.Slider {
    display: inherit;
  }
}

.HomepageSlider, .HomepageSlider-controls {
  .Wrapper {
    //@media('max-width: 900px') {
    //  margin-top: -150px;
    //}
    @media('max-width: 699px') {
      margin-top: -90px;
    }
    @media('min-width: 700px') and ('max-width: 899px') {
      margin-top: -150px;
    }

    @media('min-width: 900px') {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.HomepageSlider-container {
  overflow: visible;
}

.HomepageSlider-slide {
  position: relative;

  .Wrapper {
    @media('min-width: 900px') and ('max-width: 1205px') {
      margin-left: -25px;
    }
    @media('min-width: 1205px') {
      margin-left: -40px;
    }
  }
}

.HomepageSlider-content {
  position: relative;
  width: 100%;
  padding: 45px 20px 30px;
  background-color: $color-white;
  overflow: hidden;

  @media('max-width: 899px') {
    border: 2px solid $color-blue;
  }
  @media('min-width: 900px') {
    position: absolute;
    min-width: 500px;
    width: 44%;
    top: 0;
    bottom: 0;
    left: 0;
  }
  @media('min-width: 900px') and ('max-width: 1205px') {
    padding: 50px 45px 40px;
  }
  @media('min-width: 1205px') {
    padding: 65px 60px 55px;
  }

  &:hover, #{$keyboard} &:focus {
    .Button {
      color: $color-blue;

      &::after {
        background-color: $color-blue;
      }
    }
  }
}

.HomepageSlider-contentInner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.HomepageSlider-image {
  position: relative;

  @media('max-width: 899px') {
    padding-top: 56%;
  }
  @media('min-width: 900px') and ('max-width: 1204px') {
    height: 500px;
  }
  @media('min-width: 1205px') {
    height: 580px;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }
}

.HomepageSlider-heading {
  margin-bottom: 10px;

  @media('min-width: 900px') and ('max-width: 1205px') {
    margin-bottom: 12px;
  }
  @media('min-width: 1205px') {
    margin-bottom: 15px;
  }
}

.HomepageSlider-title {
  @include font-extrabold($font-family: $font-family-title, $text-transform: uppercase);
  color: $color-dark;
  font-size: rem-calc(28);
  line-height: 1.1;

  @media('min-width: 900px') and ('max-width: 1205px') {
    font-size: rem-calc(45);
  }
  @media('min-width: 1205px') {
    font-size: rem-calc(47);
  }

  & + .HomepageSlider-subtitle {
    margin-top: 5px;
  }

  a {
    @include hover-all;
  }
}

.HomepageSlider-subtitle {
  color: $color-red;
  font-size: rem-calc(17);
  @include font-title;

  @media('min-width: 900px') and ('max-width: 1205px') {
    font-size: rem-calc(22);
  }
  @media('min-width: 1205px') {
    font-size: rem-calc(28);
  }
}

.HomepageSlider-text p,
.HomepageSlider-text .RichText p {
  margin-bottom: 15px;
  color: $color-darkPrimary;
  font-size: rem-calc(13);
  @include font-title;

  @media('min-width: 900px') and ('max-width: 1205px') {
    margin-bottom: 20px;
    font-size: rem-calc(15);
  }
  @media('min-width: 1205px') {
    margin-bottom: 25px;
    font-size: rem-calc(18);
  }
}

.HomepageSlider-link {
  flex: 1;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}


// -------
// Options
// -------

.HomepageSlider-controls {
  pointer-events: none;

  // Controls visible over the future slides
  & ~ .HomepageSlider-slide {
    z-index: -1;
  }

  ul {
    z-index: 1;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30px;
    pointer-events: auto;

    //@media('max-width: 699px') {
    //  top: 117px;
    //}
    //@media('min-width: 700px') and ('max-width: 899px') {
    //  top: 152px;
    //}
    @media('max-width: 899px') {
      padding-top: 56%;
      top: 0;
      right: 22px;
    }
    @media('min-width: 900px') {
      width: 44%;
      min-width: 500px;
      left: 0;
      bottom: 0;
    }
    @media('min-width: 900px') and ('max-width: 1204px') {
      margin-left: -25px;
    }
    @media('min-width: 1205px') {
      margin-left: -40px;
    }
  }
}

.HomepageSlider-bullets {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  @media('max-width: 699px') {
    top: -43px;
  }
  @media('min-width: 700px') and ('max-width: 899px') {
    top: -68px;
  }

  span {
    width: 50px;
    height: 10px;
    margin-left: 20px;
    background-color: $color-gray;
    border-radius: 0;
    opacity: 1;

    &.swiper-pagination-bullet-active {
      background-color: $color-red;
    }
  }
}
