// =================
// Contributors List
// =================

@media ($mq-upto-tablet) {
  .ContributorsList-main.Section.Section--lightgreige {
    padding-top: 45px;
  }

  .ContributorsList-list {
    .Section &.Wrapper {
      margin-top: 0;
    }
  }


  .ContributorsList-anchor {
    position: absolute;
    margin-top: -80px;
  }
}



.ContributorsList {
  position: relative;
}

.ContributorsList-container{
  background-color: $color-greige;
}

.ContributorsList-letter {
  color: $color-dark;
}

.ContributorsList-letterList li a {
  @include text-underline($color: $color-darkGreen, $text-decoration-color: $color-darkGreen);
  transition: color .25s ease-in-out,
              text-decoration-color .25s ease-in-out;

  &:hover, #{$keyboard} &:focus {
    color: $color-darkGreen;
    text-decoration-color: $color-darkGreen;
  }
}
