// ================
// Footer component
// ================


.Footer {
  color: $color-white;
  background-color: $color-blue;
}

.Footer-top {
  padding: 27px 0 10px;
  border-bottom: 1px solid rgba($color-white, .5);

  @media($mq-tablet) {
    padding: 42px 0 30px;
  }
  @media($mq-desktop) {
    padding: 60px 0 35px;
  }
}

.Footer-bottom .Wrapper {
  position: relative;
}

.Footer-list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  @media($mq-phone) {
    flex-direction: column;
  }

  .Footer-top & {
    justify-content: space-between;
    width: 100%;

    & > li {
      @media($mq-phone) {
        width: 100%;
      }
      @media($mq-from-tablet) {
        margin-bottom: 10px;
      }
    }
  }

  .Footer-bottom & {
    padding-top: 22px;
    padding-bottom: 22px;

    @media('min-width: 700px') and ('max-width: 999px') {
      margin: 0 -20px;
    }
    @media('min-width: 1000px') {
      width: calc(100% + 65px);
      margin: 0 -45px;
    }

    li:not(:last-child) {
      @media($mq-phone) {
        margin-bottom: 10px;
      }
      @media($mq-from-tablet) {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          width: 1px;
          height: 100%;
          top: 50%;
          right: -1px;
          background-color: rgba($color-white, .5);
          transform: translateY(-50%);
        }
      }
    }

    li a, li.Footer-legal {
      display: inline-block;
      font-size: rem-calc(14);

      @media('min-width: 700px') and ('max-width: 999px') {
        font-size: rem-calc(13);
      }
      @media('min-width: 700px') and ('max-width: 999px') {
        padding: 12px 20px 8px;
      }
      @media('min-width: 1000px') {
        padding: 12px 45px 8px;
      }
    }
  }

  li a {
    @include text-underline;
  }
}

.Footer-item {
  &--main {
    @media($mq-phone) {
      margin-bottom: 25px !important;
    }
    @media($mq-tablet) {
      margin-bottom: 40px !important;
    }
    @media($mq-upto-tablet) {
      width: 100%;
    }
  }

  &--columns {
    @media($mq-tablet) {
      width: 75%;
    }
    @media($mq-desktop) {
      width: calc(75% - 240px);
    }

    & > ul {
      @media($mq-from-tablet) {
        display: flex;
      }
      @media($mq-desktop) {
        justify-content: space-around;
      }
    }
  }

  &--social {
    @media($mq-tablet) {
      width: 25%;
    }
  }
}

.Footer-listItem {
  @media($mq-phone) {
    cursor: pointer;
  }
  @media($mq-tablet) {
    flex: 1;
  }

  // Close state, default
  .Footer-listTitle .icon {
    transform: rotate(-270deg);
  }

  // Open state
  &.is-active .Footer-listTitle .icon {
    transform: rotate(-90deg);
  }
}

.Footer-listTitle {
  position: relative;
  display: block;
  padding-bottom: 15px;
  @include font-bold;

  .icon {
    position: absolute;
    width: 14px;
    height: 15px;
    top: 19px;
    right: 0;
    transition: transform .25s ease-out;

    @media($mq-from-tablet) {
      display: none;
    }
  }

  @media($mq-phone) {
    padding-top: 16px;
    padding-bottom: 13px;

    &:focus {
      outline: none;
    }

    &::before {
      content: '';
      position: absolute;
      height: 1px;
      top: 0;
      left: -20px;
      right: -20px;
      background-color: rgba($color-white, .5);
    }
  }
}

.Footer-sublist {
  @media($mq-phone) {
    &:not(.Footer-sublist--social) {
      display: none; // Required for Foundation's Accordion init
    }

    li:last-child {
      margin-bottom: 6px;
    }
  }

  li a {
    display: inline-block;
    font-size: rem-calc(14);
    @include text-underline;
  }

  // Columns variant
  &--columns {
    li {
      margin: 0 -5px;

      a {
        padding: 5px;
      }
    }
  }

  // Social networks variant
  &--social.SocialNetworks {
    li a {
      border-color: rgba($color-white, .5);
      color: $color-white;

      &:hover, #{$keyboard} &:focus {
        background-color: $color-white;
        border-color: $color-white;
        color: $color-blue;
      }
    }
  }
}

.Footer-logo {
  position: relative;
  display: inline-block;

  @media($mq-desktop) {
    top: -3px;
  }

  .icon {
    width: 180px;
    height: 36px;

    @media($mq-from-tablet) {
      width: 210px;
      height: 42px;
    }
  }
}

.Footer-backtoTop {
  position: absolute;
  width: 40px;
  height: 40px;
  top: -20px;
  right: 20px;
  background-color: $color-blue;
  border: 1px solid rgba($color-white, .5);
  border-radius: 50%;
  transition: background-color .25s ease-in-out, border-color .25s ease-in-out, color .25s ease-in-out;

  @media($mq-desktop) {
    width: 65px;
    height: 65px;
    top: -32px;
  }

  &:hover, #{$keyboard} &:focus {
    background-color: $color-yellow;
    border-color: $color-yellow;
    color: $color-blue;
  }

  .icon {
    position: absolute;
    width: 18px;
    height: 18px;
    top: 50%;
    left: 50%;
    transform: translate(-48%, -50%) rotate(-90deg);

    @media($mq-desktop) {
      width: 30px;
      height: 30px;
    }
  }
}
