// =========================
// Social Networks component
// =========================

.SocialNetworks {
  display: flex;
  width: calc(100% + 16px);
  margin: 0 -8px;

  li a {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 28px;
    margin: 5px 8px;
    background-color: transparent;
    border: 1px solid $color-gray;
    border-radius: 50%;
    color: $color-dark;
    cursor: pointer;
    transition: background-color .25s ease-in-out,
                border-color .25s ease-in-out,
                color .25s ease-in-out;

    @media($mq-from-tablet) {
      margin: 0 8px 8px;
    }

    &:hover, #{$keyboard} &:focus {
      background-color: $color-white;
      border-color: $color-dark;
    }

    .icon {
      position: absolute;
      width: 12px;
      height: 12px;
      margin: 7px;
    }
  }

  // Color variants
  &--light {
    li a {
      background-color: transparent;
      border: 1px solid $color-white;
      color: $color-white;

      &:hover, #{$keyboard} &:focus {
        background-color: $color-white;
        border-color: $color-white;
        color: $color-dark;
      }
    }
  }
  &--toYellow {
    li a {
      background-color: transparent;
      border: 1px solid $color-white;
      color: $color-white;

      &:hover, #{$keyboard} &:focus {
        background-color: $color-yellow;
        border-color: $color-yellow;
        color: $color-dark;
      }
    }
  }
}
