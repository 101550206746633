// ===================
// Read More component
// ===================

.ReadMore-button {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: -20px;
  pointer-events: none;
  color: $color-green;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 60px;
    bottom: 100%;
  }

  &:hover span, #{$keyboard} &:focus span {
    color: $color-red;
  }

  span {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    color: $color-darkGray;
    font-size: rem-calc(13.3);
    pointer-events: auto;
    transition: color .25s ease-in-out;
  }

  .icon {
    width: 40px;
    height: 40px;
    margin-left: 2px;
    margin-right: -10px;
    color: $color-red;
  }

  &:focus, &:hover  {
    outline: 0;
  }
}

.Hero-textButton .ReadMore-button span {
  padding: 40px 0 10px 0;
  font-size: 1.125rem;
}

.Truncate-button:not(.Truncate-unexpandButton) .ReadMore-button::before {
  background: linear-gradient(to bottom, rgba($color-lightGreige, 0), $color-lightGreige);
}

.Truncate[aria-expanded="false"] ~ .Truncate-button .ReadMore-hideButton {
  display: none;
}

.Truncate[aria-expanded="true"] ~ .Truncate-button .ReadMore-showButton {
  display: none;
}
