// ==========
// Newsletter
// ==========

.Newsletter-iframe {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-dark;

  .seFormBody{
    position:relative;

    padding:10px 20px;
    max-width: calc(1200px + 20px * 2);
  }

}

.WishList .Newsletter-iframe {
  border-bottom: 1px solid rgba($color-white, .5);
}

// Newsletter iframe

.Newsletter {
  iframe {
    width: 100%;
    border: none;

  }
}

.Newsletter-iframe {
  iframe{

    @media ($mq-from-tablet) {
      height:195px;
    }

    @media only screen and (max-width: 700px) {
      min-height:400px;
    }
  }
}
