// ====================
// News Embed component
// ====================

// ------
// Header
// ------

.RichText {
  p.NewsEmbed-title {
    @include font-extrabold($font-family: $font-family-title);
  }

  p.NewsEmbed-subtitle {
    @include font-title;
  }

  .NewsEmbed-content {
    p {
      font-size: rem-calc(18);
    }
  }

  .NewsEmbed-downloads {
    .Button {
      @extend .Button--dark;

      &:hover, #{$keyboard} &:focus {
        .icon {
          color: $color-dark;
        }
      }

      .icon {
        width: 22px;
        height: 22px;
        margin: -3px 10px 0 0;
        color: $color-green;
      }
    }
  }
}