// ===============
// Book attachment
// ===============

.BookAttachment {
  position: relative;
  max-width: 405px;
  width: 100%;
  margin-top: 0;

  @media($mq-phone) {
    margin-bottom: 30px;
  }
  @media($mq-from-tablet) {
    max-width: 300px;
  }
}

.BookAttachment .Slider-bullets {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  //bottom: -35px;
  list-style: none;

  @media($mq-from-tablet) {
    display: none;
  }
}

.BookAttachment-container {
  @media($mq-phone) {
    overflow: visible;
  }
}

.BookAttachment-slide {
  @include xy-cell(shrink, $gutters: 0);
  display: flex;
  align-items: center;
  justify-content: center;
}

.BookAttachment-slide img {
  max-height: 90px;
}

// ------------------
// Navigation buttons
// ------------------

.BookAttachment-button {
  position: absolute;
  padding: 20px;
  top: 50%;
  transform: translateY(-50%);

  @media($mq-phone) {
    display: none;
  }

  .icon {
    width: 14px;
    height: 24px;
    color: $color-darkGray;
  }

  &Prev {
    left: -65px;
  }

  &Next {
    right: -65px;

    .icon {
      transform: rotate(180deg);
    }
  }
}

#spotlight .icon {
  pointer-events: auto;
  position: relative;
  margin-right: 20px;
  @media ($mq-desktop) {
    margin-right: 40px;
  }
}

#spotlight.show {
  z-index: 999999;
}

#spotlight .header {
  height: 75px !important;
}

#spotlight .icon:hover:before {
  top: 50px;
  color: #fff;
  position: absolute;
  left: -30px;
  background-color: rgba(0,0,0,.45);
}

#spotlight .icon.fullscreen:hover:before {
  content: 'Afficher en plein écran';
  left: -50px;
}

#spotlight .icon.autofit:hover:before {
  content: 'Recentrer';
  left: -20px;
}

#spotlight .icon.zoom-out:hover:before {
  content: 'Zoom arrière';
}

#spotlight .icon.zoom-in:hover:before {
  content: 'Zoom avant';
}

#spotlight .icon.theme:hover:before {
  content: 'Couleur de fond';
  left: -40px;
}
#spotlight .icon.player:hover:before {
  content: 'Diaporama';
}

#spotlight .icon.close:hover:before {
  content: 'Fermer';
  left: -20px;
}
