// ====================
// News Embed component
// ====================

.NewsEmbed {
  display: table;

  li:not(:first-of-type) & {
    margin-top: 20px;
  }
}


.NewsEmbed img {
  max-width: 330px;
  width: 100%;
  margin-bottom: 40px;

  @media('min-width: 1000px') {
    display: table-cell;
    float: left;
    margin-right: 60px;
  }
}

.NewsEmbed-text {
  margin-bottom: 40px;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
}


// ------
// Header
// ------

.RichText {
  p.NewsEmbed-title {
    @include font-semibold;
    font-size: rem-calc(26);
    line-height: 1.2;
  }

  p.NewsEmbed-subtitle {
    @include font-light;
    font-size: rem-calc(26);
    line-height: 1.2;
  }

  .NewsEmbed-content {
    p {
      @include font-title;
      font-size: rem-calc(22);
    }
  }

  .NewsEmbed-footer {
    p {
      color: $color-mediumGray;
      @include font-base;
      font-size: rem-calc(13.3);
      line-height: 1.6;
    }
  }

  .NewsEmbed-downloads {
    margin-top: 30px;

    .Button {
      position: relative;

      &:hover, #{$keyboard} &:focus {
        background-color: $color-darkGray;
        color: $color-white;

        .icon {
          color: $color-white;
        }
      }

      .icon {
        width: 30px;
        height: 30px;
        margin: -7px 5px 0 -8px;
        color: $color-red;
        transform: rotate(90deg);
        transition: color .25s ease-in-out;
      }
    }
  }
}