// =============
// Articles List
// =============

.ArticlesList {
  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 40px -15px;

  @media($mq-from-tablet) {
    margin: 40px -15px 0;
  }

  li {
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    margin: 15px 15px 20px;

    @media('min-width: 700px') and ('max-width: 900px') {
      width: calc(100% / 2 - 30px);
    }
    @media('min-width: 900px') and ('max-width: 1100px') {
      width: calc(100% / 3 - 30px);
    }
    @media('min-width: 1100px') and ('max-width: 1220px') {
      width: calc(100% / 2 - 30px);
    }
    @media('min-width: 1220px') {
      flex: 1 0 calc(100% / 3 - 30px);
      max-width: calc(100% / 3 - 30px);
    }
    @media($mq-from-tablet) {
      margin: 15px 15px 40px;
    }

    .NewsItem {
      width: 100%;
      height: 100%;
    }

    .NewsItem-text {
      height: 100%;
    }

    .NewsItem-container {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .NewsItem-image img {
      width: 100%;
    }
  }

  .SearchResults-show {
    margin-bottom: 30px;

    @media($mq-upto-tablet) {
      margin-top: 30px;
      margin-bottom: 60px;
    }
  }
}

.ArticlesList-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}


.ArticlesList.desktopOnly {
  display: none;

  @media('min-width: 1220px') {
    display: flex;
  }
}


.ArticlesList.uptoTabletOnly {
  @media('min-width: 1220px') {
    display: none;
  }
}
