// =========
// News Item
// =========

.NewsItem {
  position: relative;
  transition: color .25s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 3px solid transparent;
    transition: border-color .25s ease-in-out;
  }
}

.NewsItem-image {
  position: relative;
  width: 100%;
  padding-top: 55%;

  @media('min-width: 700px') and ('max-width: 800px') {
    padding-top: 62%;
  }
  @media('min-width: 800px') and ('max-width: 1100px') {
    padding-top: 50%;
  }
  @media($mq-desktop) {
    padding-top: 77.5%;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  &--video {
    @media($mq-desktop) {
      padding-top: 36.765%;
    }

    .NewsItem-imageLarge {
      @media($mq-upto-tablet) {
        display: none;
      }
    }
    .NewsItem-imageNormal {
      @media($mq-desktop) {
        display: none;
      }
    }

    .icon {
      position: absolute;
      width: 50px;
      height: 50px;
      top: 50%;
      left: 50%;
      border-radius: 100%;
      box-shadow: 0 0 10px 0 $color-mediumGray;
      transform: translate(-50%, -50%);
    }
  }
}

.NewsItem-text {
  padding: 20px 35px 45px;
  background-color: $color-white;
  border: 1px solid $color-gray;

  .icon {
    position: absolute;
    width: 25px;
    height: 25px;
    right: 26px;
    bottom: 23px;
  }
}

.NewsItem-type {
  margin-bottom: 5px;
  @include font-title($text-transform: uppercase);
  font-size: rem-calc(12);
}

.NewsItem-dates {
  font-size: rem-calc(14);
}

.NewsItem-title {
  @include hover-all;
  @include font-bold($font-family: $font-family-title);
  font-size: rem-calc(16);
  text-align: left;
}