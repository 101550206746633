// ==============
// RichText style
// ==============

.RichText {
  color: $color-dark;

  // titles
  h1, h2, h3, h4, h5, h6 {
    &:not([class]),
    &[class*="text"] {
      color: $color-darkGreen;
      @include font-bold($font-family: $font-family-title);
      line-height: 1.25;
    }
    &:not(:first-child) {
      line-height: 1.5;
    }
  }

  // lists
  ul:not([class]),
  ol:not([class]) {
    ul, ol {
      margin-bottom: 0.5rem;
    }
  }

  p:not([class]),
  p[class*=text],
  ul:not([class]) li, ol:not([class]) li {
    font-size: 1.1875rem;
  }

  // blockquote
  blockquote {
    em {
      color: $color-darkGray;
      @include font-title;
    }
  }

  // table
  table {
    tbody tr {
      &:nth-child(even) {
        background-color: $color-lightGreige;
      }
      &:nth-child(odd) {
        background-color: $color-white;
      }
    }

    th {
      @include font-bold($font-family: $font-family-title)
    }
  }


  // -----------------
  // eZ custom classes
  // -----------------

  // Text type
  .small-text p {
    color: $color-darkGray;
    @include font-title;
  }

  // Text colors
  .green-text {
    color: $color-darkGreen;
  }

  // Highlight
  .highlight-content {
    background-color: $color-greige;
  }
}


.RichText .multipleVideos .video-embed:hover::after {
  border: 3px solid $color-darkGreen !important;
}

.Book-videos .multipleVideos .fromTabletOnly.RichText {
  margin-top: 15px;
  width: 100%;
}

.RichText .video-embed iframe {
  z-index: 1;
}
