// ===========
// News Slider
// ===========

.NewsSlider {
  .Slider-buttonPrev .icon {
    transform: inherit;
  }
  .Slider-buttonNext .icon {
    transform: rotate(180deg);
  }
}
