// ===================
// Cookie Banner style
// (Klaro override)
// ===================

#klaro {
  .cookie-notice, .cookie-modal .cm-modal {
    background-color: $color-darkPrimary;
    border-radius: 0;
    box-shadow: none;

    @media($mq-from-tablet) {
      max-width: 550px;
    }
  }

  .cookie-notice{
    @media($mq-from-tablet) {
      max-width: 900px;
    }
  }

  .cn-body, .cookie-modal .cm-modal {
    padding: 10px 20px 20px;
    font-size: rem-calc(13.3);
    line-height: 1.5;

    @media($mq-from-tablet) {
      padding: 20px 30px 30px;
    }
  }

  .cn-ok {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 0;
  }

  .cm-btn {
    margin-top: 10px;
    padding: 7px 10px 4px;
    border-radius: 0;
    line-height: 1.2;
  }

  .cm-btn-success,.cm-btn-danger {
    margin-right: 15px;
    background-color: $color-primary;
    border: 1px solid $color-primary;
    transition: background-color .25s ease-in-out,
    border-color .25s ease-in-out;

    &:hover, #{$keyboard} &:focus {
      background-color: rgba($color-primary, .8);
      border-color: rgba($color-primary, .8);
    }
  }

  .cm-link {
    display: inline-block;
    margin-top: 10px;
    padding: 7px 0 4px;
    color: rgba($color-white, .8);
    line-height: 1.2;
    white-space: nowrap;
    @include text-underline;

    &:hover, #{$keyboard} &:focus {
      color: $color-white;
    }
  }
}

// MODAL

#klaro .klaro .cookie-modal .cm-modal {
  @media ($mq-phone) {
    max-height: 100%;
    height: 100%;
  }
}

#klaro .cookie-modal .cm-modal h1 {
  font-family: "Poppins", Arial, sans-serif;
}

#klaro .cookie-notice {
  //top: auto;
  bottom: 0px;
  left: 0;
  right: 0;
  margin-left:auto;
  margin-right:auto;
  z-index: 10000;

  @media ($mq-upto-tablet) {
    bottom: 0;
    left: auto;
  }
}


#klaro .klaro .cookie-modal {
  z-index: 100000;
}

#klaro .klaro .cookie-modal a,
#klaro .klaro .cookie-notice .cn-body p a {
  color: $color-green
}

#klaro .cookie-modal .cm-modal h1 {
  font-family: "Poppins", Arial, sans-serif;
}

#klaro .klaro .cookie-modal .cm-list-input:checked + .cm-list-label .slider,
#klaro .klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider {
  background-color: $color-green;
}
