// ===========
// News Slider
// ===========

.NewsSlider {

  .Slider-wrapper {
    margin-top: 38px;
    margin-bottom: 30px;
  }

  .Slider-slide {
    width: 93%;
    height: auto;

    @media($mq-tablet) {
      width: calc(95% / 2 - 15px);
    }
    @media('min-width: 1100px') and ('max-width: 1400px') {
      width: calc(93% / 3 - 20px);
    }
    @media('min-width: 1400px') {
      width: calc(100% / 4 - 23px);
    }

    &--large {
      @media('min-width: 1100px') and ('max-width: 1400px') {
        width: calc(46.375% - 20px);
      }
      @media('min-width: 1400px') {
        width: calc(50% - 20px);
      }
    }
  }

  .NewsItem, .NewsItem-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .NewsItem-text {
    height: 100%;
  }
}
