// =========
// Book Flip
// =========

.BookFlip-container {
  perspective: 1200px;
}

.BookFlip {
  position: relative;
  transform-style: preserve-3d;
  transition: all 1s ease;

  &--back {
    transform: rotateY(-180deg);
  }
}

.BookFlip-cover {
  transform-style: preserve-3d;
  backface-visibility: hidden;
  // Use the following to make it work on Safari
  -webkit-backface-visibility: hidden;
  transform: translateZ(-1px);
}

.BookFlip-coverBack {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: rotateY(180deg);
}

.BookFlip-button {
  z-index: 3;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  right: -25px;
  background-color: $color-lightenGray;
  border: 1px solid;
  border-radius: 50%;
  color: $color-gray;
  transform: translateY(-50%);
  transition: background-color .25s ease-in-out,
              color .25s ease-in-out ;

  &:hover, #{$keyboard} &:focus {
    background-color: $color-white;
    color: $color-darkGray;
  }

  @media($mq-from-tablet) {
    width: 65px;
    height: 65px;
    right: -32px;
  }
  @media print {
    display: none;
  }

  .icon {
    position: absolute;
    width: 50px;
    height: 50px;
    color: $color-darkGray;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-180deg);

    @media($mq-from-tablet) {
      width: 65px;
      height: 65px;
    }
  }

  // Style variant
  .BookItem & {
    width: 50px;
    height: 50px;
    right: -25px;
    background-color: $color-white;

    @media('max-width: 400px') {
      width: 40px;
      height: 40px;
      right: -20px;
    }

    &:hover {
      background-color: $color-dark;
      border-color: $color-dark;

      .icon {
        color: $color-white;
      }
    }

    .icon {
      width: 50px;
      height: 50px;
      transition: color .25s ease-in-out;

      @media('max-width: 400px') {
        width: 40px;
        height: 40px;
      }
    }
  }
}

// Keyboard detection
#{$keyboard} .BookItem .BookFlip-button:focus {
  background-color: $color-dark;
  border-color: $color-dark;

  .icon {
    color: $color-white;
  }
}