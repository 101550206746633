// =======================
// Search Facets component
// =======================

.SearchFacets {
  background-color: $color-greige;

  @media($mq-upto-tablet) {
    position: relative;
    margin: 0 -20px;
  }
  @media($mq-desktop) {
    z-index: 1;
    max-width: 315px;
    width: 100%;
    padding-right: 30px;

    &::before {
      content: '';
      z-index: -1;
      position: absolute;
      width: calc(50vw - 293px);
      height: calc(100% - 69px);
      top: 69px;
      left: 0;
      background-color: $color-greige;
    }
    &::after {
      content: '';
      z-index: -1;
      position: absolute;
      top: 133px;
      left: 0;
      width: calc(50vw - 293px);
      height: 1px;
      background-color: $color-dark;
    }
  }
}

.SearchFacets-title {
  @media($mq-upto-tablet) {
    h2.Title {
      display: none;
    }
  }
  @media($mq-desktop) {
    margin-right: -30px;

    button.Title {
      display: none;
    }
  }

  .Title {
    width: 100%;
    margin: 25px 0;
    text-align: left;

    @media($mq-upto-tablet) {
      position: relative;
      width: 100%;
      margin: 20px 0 25px;
      padding-left: 20px;
      padding-right: 20px;
    }
    @media($mq-desktop) {
      span::after {
        z-index: 1;
      }
    }

    .icon {
      position: absolute;
      width: 17px;
      height: 17px;
      color: $color-dark;
      top: 13px;
      right: 16px;
      transform: rotate(180deg);

      @media($mq-desktop) {
        display: none;
      }
    }
  }
}

.SearchFacets-authorSearch {
  position: relative;
  margin-bottom: 20px;
  font-size: rem-calc(15);

  input {
    padding: 10px 13px 9px 42px;
  }

  .SearchFacets-authorIcon {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    left: 0;

    .icon {
      position: absolute;
      width: 15px;
      height: 15px;
      top: 13px;
      right: 11px;
    }
  }
}

.SearchFacets-authorList {
  z-index: 1;
  max-height: 200px;
  overflow-y: scroll;

  li {
    padding-top: 4px;
    padding-bottom: 4px;

    a {
      font-size: rem-calc(15);
      @include text-underline;
    }
  }

  .icon {
    width: 15px;
    height: 15px;
    vertical-align: middle;
    color: $color-darkGray;
  }
}

.SearchFacets-container > * {
  margin: 25px 0;
}

.SearchFacets-list {
  margin-top: -10px;
}

.SearchFacets-accordion {
  .Title {
    margin-top: 15px;
    margin-bottom: 17px;
  }
}


// --------
// Thematic
// --------

.SearchFacets-thematicSearch {
  position: relative;
  margin-bottom: 20px;
  font-size: rem-calc(15);

  input {
    padding: 10px 13px 9px 42px;
  }

  button {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    right: 0;

    .icon {
      position: absolute;
      width: 15px;
      height: 15px;
      top: 13px;
      right: 11px;
      transform: rotate(-90deg);
    }
  }
}

.SearchFacets-thematicIcon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;

  .icon {
    width: 20px;
    height: 20px;
    color: $color-dark;
  }
}

.SearchFacets-thematicText {
  margin-bottom: 15px;
  font-size: rem-calc(15);
  @include font-medium;
}

.SearchFacets-thematicList > li {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 8px;
}


// -----
// Price
// -----

.SearchFacets-price {
  .accordion-content {
    padding: 10px 15px;
  }
  .noUi-connect {
    background-color: $color-white;
  }
  .noUi-target {
    background-color: initial;
    border-color: $color-gray;
  }
  .noUi-horizontal {
    height: 15px;
  }
  .noUi-horizontal .noUi-handle {
    width: 30px;
    height: 30px;
    top: -8px;
    background-color: $color-white;
    border: 1px solid $color-gray;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba($color-darkGray, 0);
    cursor: grab;
    will-change: border-color, box-shadow;
    transition: border-color .25s ease-in-out,
                box-shadow .25s ease-in-out;

    &:hover {
      border-color: $color-darkGray;

      &::after {
        background-color: rgba($color-darkGray, .25);
      }
    }
    &:focus {
      outline: none;
      border-color: $color-darkGray;
      box-shadow: 0 0 5px $color-darkGray;

      &::after {
        background-color: $color-darkGray;
      }
    }

    &::before {
      display: none;
    }
    &::after {
      height: 24px;
      width: 24px;
      background-color: rgba($color-darkGray, 0);
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: background-color .25s ease-in-out;
    }
  }
  .noUi-tooltip {
    background: initial;
    border: none;
  }
  .noUi-horizontal .noUi-tooltip {
    padding-top: 8px;
    top: 100%;
    bottom: auto;
  }
}

.SearchFacets-range ~ .Button {
  visibility: hidden;
  display: block;
  margin-top: 50px;
  opacity: 0;
  transition: all .25s ease-in-out;

  &.visible {
    visibility: visible;
    opacity: 1;
  }
}


// -------------
// Event locator
// -------------

.SearchFacets-eventLocator {
  margin-top: 40px;
}

.SearchFacets-eventLocatorText {
  margin-bottom: 15px;
  font-size: rem-calc(15);
  @include font-medium;
}

.SearchFacets-eventLocatorSearch {
  position: relative;
  margin-bottom: 6px;
  font-size: rem-calc(15);

  input {
    padding: 10px 13px 9px 42px;
  }

  button {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    right: 0;

    .icon {
      position: absolute;
      width: 15px;
      height: 15px;
      top: 12px;
      right: 12px;
      transform: rotate(-90deg);
    }
  }
}

.SearchFacets-eventLocatorIcon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;

  .icon {
    width: 20px;
    height: 20px;
    color: $color-dark;
  }
}

.SearchFacets-geolocationButton {
  margin-bottom: 15px;
  font-size: rem-calc(13);
  text-decoration: underline;
  transition: color .25s ease-in-out;

  &:hover, #{$keyboard} &:focus {
    color: $color-red;
  }
}


// ----------
// Off Canvas
// ----------

.SearchFacets-canvas {

  @media($mq-phone) {
    padding: 100px 20px 20px;
  }
  @media($mq-tablet) {
    padding: 20px;
  }
  @media($mq-upto-tablet) {
    width: 100%;
    background: $color-lightenGray;
    transform: translateX(100%);
  }
  @media($mq-desktop) {
    z-index: 0 !important;
    position: initial;
    width: auto;
    height: auto;
    background: inherit;
    visibility: visible !important;
    overflow: initial;
    transform: initial;
    transition: none;
  }
}

.SearchFacets-header {
  @media($mq-desktop) {
    display: none;
  }

  .close-button {

    @media($mq-phone) {
      top: 100px;
    }

    width: 40px;
    height: 40px;
    color: $color-dark;
    top: 20px;
    right: 10px;
    font-size: inherit;

    .icon {
      width: 20px;
      height: 20px;
    }
  }
}
