// ====================
// Search Results style
// ====================

.SearchResults {
  z-index: 0;
  position: relative;
  &:not(.isPdf) {
  background-color: $color-lightGreige;
  }
}

.SearchResults-header {
  position: relative;
  padding-top: 95px;

  .Wrapper {
    display: flex;
    justify-content: space-between;
  }
}


// -----------
// Header Left
// -----------

.SearchResults-title {
  flex: 1;
  @include font-semibold;
  font-size: rem-calc(30);
  line-height: 1.1;

  @media($mq-from-tablet) {
    font-size: rem-calc(45);
  }
}

.SearchResults-categories {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  .Button--active {
    order: -1;
  }
}

.SearchResults-category {
  margin-top: 10px;
  margin-right: 10px;
}


// ------------
// Header Right
// ------------

.SearchResults-headerRight {
  max-width: 243px;
  width: 100%;
  margin-top: -69px;
  margin-left: 30px;
  padding: 69px 0 30px 30px;
  background-color: $color-greige;

  @media($mq-upto-tablet) {
    display: none;
  }

  &::before {
    content: '';
    z-index: -1;
    position: absolute;
    width: calc(50vw - 365px);
    height: 100%;
    top: 0;
    right: 0;
    background-color: $color-greige;
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }
}

.SearchResults-options {
  li:not(:last-child) {
    margin-bottom: 10px;
  }

  .Button {
    display: block;
    position: relative;
    text-align: left;
    padding: 12px 30px 8px 12px;

    .icon {
      position: absolute;
      width: 18px;
      height: 18px;
      top: 14px;
      right: -2px;
    }
  }

  // Download button
  li:last-child .Button .icon {
    transform: rotate(90deg);
  }
}


// ----
// List
// ----

.SearchResults-list {
  position: relative;
  padding-top: 69px;

  .Wrapper {
    display: flex;

    @media($mq-upto-tablet) {
      flex-direction: column;
    }
  }
}

.SearchResults-main {
  width: 100%;
  padding-top: 25px;
  // Avoid anchoring on clicking read more button
  overflow-anchor: none;

  @media($mq-tablet) {
    padding: 30px 0 45px;
  }
  @media($mq-desktop) {
    padding: 65px 0 45px 45px;

    &:not(.empty)::after {
      content: '';
      z-index: -1;
      position: absolute;
      top: 133px;
      right: 0;
      height: 1px;
      background-color: $color-dark;
      width: 100%;
    }
  }
}


// -------
// Taglist
// -------

.SearchResults-tags {
  position: relative;

  @media($mq-upto-tablet) {
    margin-bottom: 15px;
  }
}

.SearchResults-tagsContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  font-size: rem-calc(14);

  @media($mq-phone) {
    flex-direction: column;
  }
  @media($mq-desktop) {
    position: absolute;
    align-items: flex-end;
    top: -32px;
    left: 0;
  }
}

.SearchResults-taglist {
  flex: 1;

  ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;

    @media($mq-desktop) {
      position: absolute;
      width: calc(100% - 160px);
      margin-bottom: -4px;
      bottom: 0;
    }
  }
}

.SearchResults-tag {
  position: relative;
  padding: 5px 28px 2px 12px;
  margin: 5px;
  border: 1px solid $color-darkGray;
  border-radius: 15px;
  text-align: left;

  .icon {
    position: absolute;
    width: 10px;
    height: 10px;
    margin: 0;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}

.SearchResults-resetTags {
  padding: 10px 5px 5px;
  @include font-medium;
  text-decoration: underline;
  transition: color .25s ease-in-out;

  @media($mq-phone) {
    margin-left: -5px;
  }
  @media($mq-from-tablet) {
    margin-right: -5px;
  }
  @media($mq-desktop) {
    padding: 2px 5px 0;
  }

  &:hover, #{$keyboard} &:focus {
    color: $color-red;
  }
}


// ---------
// Show More
// TODO: Use ShowMore component instead
// ---------

.SearchResults-show {
  display: flex;
  justify-content: center;
  width: 100% !important;

  & ~ * { // Hide all next siblings
    display: none !important;
  }
}

.SearchResults-showContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & > p {
    margin-bottom: 10px;
  }
}

.SearchResults-showProgress {
  position: relative;
  width: 200px;
  height: 10px;
  margin-bottom: 25px;

  span:first-child {
    position: absolute;
    width: 200px;
    height: 10px;
    background-color: $color-lightGray;
    border: 1px solid $color-gray;
    border-radius: 10px;
    top: 0;
    left: 0;
  }

  span:last-child {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--progress);
    height: 10px;
    background-color: $color-darkGray;
    border-radius: 10px;
  }
}


// ------
// Footer
// ------

.SearchResults-footer {
  padding-top: 45px;
  padding-bottom: 35px;

  @media($mq-desktop) {
    display: none;
  }
  @media($mq-tablet) {
    margin-top: -45px;
  }

  .SearchResults-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -10px;

    @media($mq-phone) {
      flex-direction: column;
      align-items: center;
    }

    li {
      max-width: 200px;
      width: 100%;
      margin-bottom: 10px;

      @media($mq-tablet) {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
}

.SearchResults-list .BooksList {
  margin-top: 30px;
}
