.Popup{
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom: 0;
  height:100%;
  width:100%;
  z-index:100;
  
  .Popup-content{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color:rgba(0,0,0,.45);
    height:100%;
  }
  
  .Popup-content--inner{
    position:relative;
    max-width: 580px;
    background-color:#fff;
  }
  iframe{
    border:none;
  }
  
  .Popup-close{
    position:absolute;
    right:1rem;
    top:.5rem;
  }
  
}

.Popup--news{
  
  min-height:380px;
  
  .Popup-contentImage{
    img{
      display:block;
      width:100%;
    }
  }
  
  .seFormPopup{
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    min-height:200px;
    padding:0rem 1rem 1rem 1rem;
  }
  
}

.Popup--isHidden{
  display:none;
}

@media all and (max-width: 580px){
  .Popup-content--inner{
    max-width: 95% !important;
    .seFormPopup{
      min-width: unset !important;
    }
  }
}
