// ======
// Slider
// ======

.Slider {
  position: relative;
  display: inline-block;
  width: 100%;
}

.Slider-container {
  @media('max-width: 1399px') {
    overflow: visible;
  }
}

.Slider-slide {
  @include xy-cell(shrink, $gutters: 0);
}


// ------------------
// Navigation buttons
// ------------------

.Slider-button {
  position: absolute;
  padding: 20px;
  width: 90px;
  height: 140px;
  top: 112px;

  @media('max-width: 1450px') {
    width: 78px;
    height: 115px;
    top: 119px;
  }
  @media('max-width: 1399px') {
    display: none;
  }

  &[aria-disabled="false"] {
    &:hover, #{$keyboard} &:focus {
      .icon {
        color: $color-red;
      }
    }
    .icon-arrow3a {
      opacity: 0;
    }
    .icon-arrow3b {
      opacity: 1;
    }
  }

  .icon {
    position: absolute;
    width: 50px;
    height: 100px;
    color: $color-blue;
    top: 20px;
    left: 20px;
    transition: color .25s ease-in-out,
                opacity .25s ease-in-out;

    @media('max-width: 1450px') {
      width: 38px;
      height: 75px;
    }

    &-arrow3b {
      width: 52px;
      height: 104px;
      top: 18px;
      left: 19px;
      opacity: 0;

      @media('max-width: 1450px') {
        width: 39px;
        height: 76px;
      }
    }
  }

  &Prev {
    left: -112px;

    @media('max-width: 1450px') {
      left: -100px;
    }

    .icon {
      transform: rotate(180deg);
    }
  }

  &Next {
    right: -112px;

    @media('max-width: 1450px') {
      right: -100px;
    }
  }
}


// --------------
// Slider bullets
// --------------

.Slider-bullets {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: auto !important;
  margin: 0 auto;

  @media($mq-phone) {
    margin-top: 20px;
  }
  @media('min-width: 1400px') {
    display: none;
  }
}

.Slider-bullet {
  width: 26px;
  height: 26px;
  margin: 0 2px;
  padding: 8px;
  background-color: transparent;
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background-color: $color-lightGray;
    border-radius: 50%;
  }
}