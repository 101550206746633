// =================
// Homepage Networks
// =================

.HomepageNetworks-list {
  display: flex;

  @media('max-width: 900px') {
    flex-direction: column;
    max-width: 580px;
  }
  @media('min-width: 900px') {
    width: calc(100% + 24px);
    margin: 0 -12px;
  }
}

.HomepageNetworks-listItem {
  position: relative;


  &.forMobile {
    @media (min-width: 1100px) {
      display: none;
    }
  }

  @media('max-width: 900px') {
    & + & {
      margin-top: 20px;
    }
  }
  @media('min-width: 900px') {
    width: calc(50% - 24px);
    margin: 27px 12px 12px;
  }
}

.HomepageNetworks-iframe {
  position: relative;
  width: 100%;
  height: 325px;
  margin: 15px 0 30px;
  background-color: $color-lightGreige;
  background-size: contain;

  &.Instagram {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    max-width: 500px;
  }

  &.fb_iframe_widget {
    background-image: none !important;
    @extend .Section--greige;
  }

  iframe {
    max-height: 100%;
  }

  @media('min-width: 900px') {
    margin: 0;
  }

 &:not(.Instagram) img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  &.Instagram a:not(.noLoaded) {
    width: 100%;
    height: 100%;
    display: flex;
    img {
      max-height: 325px;
      margin: auto;
    }
  }

  &.Instagram a.noLoaded {
    background-color: #fff;
  }
}

.HomepageNetworks-link {
  display: flex;
  align-items: center;
  margin-top: 50px;

  @media('max-width: 900px') {
      margin-top: 30px;
  }
  @include font-medium;
  @include text-underline;
}

.HomepageNetworks-separator {
  flex: 1;
  max-width: 85px;
  height: 1px;
  margin: 10px;
  background-color: $color-blue;
}

.HomepageNetworks-icon {
  position: relative;
  width: 34px;
  height: 34px;
  padding: 8px;
  color: $color-white;
  background-color: $color-red;
  border-radius: 50%;

  .icon {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
