// ===============
// Homepage Series
// ===============

.Homepage-characters.Section--grayFooter::before {
  top: 265px;
}

.HomepageSeries .Wrapper > ul {
  display: flex;

  @media($mq-upto-tablet) {
    flex-direction: column;
  }

  & > li:last-child .CirclesList-item {
    @media($mq-desktop) {
      margin: 15px 17px 30px;
    }
  }
}

.HomepageSeries .Slider-container {
  @media($mq-desktop) {
    margin: 0 -17px;
  }
}

.HomepageSeries .CirclesList-list {
  flex-wrap: wrap;
  margin: 0;

  @media($mq-upto-tablet) {
    flex-wrap: nowrap;
  }

  .Slider-slide {
    width: calc(90% / 2);

    @media('min-width: 700px') and ('max-width: 949px') {
      width: calc(90% / 3);
    }
    @media('min-width: 950px') and ('max-width: 1099px') {
      width: calc(90% / 4);
    }
    @media($mq-desktop) {
      width: calc(90% / 3);
    }

    // Avoid white bottom background
    &:last-child::before {
      content: none;
    }
  }
}

.HomepageSeries .CirclesList-item--first {
  @media($mq-tablet) {
    flex-direction: row;
  }

  .CirclesList-image {
    @media($mq-phone) {
      width: 60%;
      padding-top: 60%;
    }
    @media('min-width: 700px') and ('max-width: 949px') {
      width: 30%;
      padding-top: 30%;
    }
    @media('min-width: 950px') and ('max-width: 1099px') {
      width: 22%;
      padding-top: 22%;
    }
    @media($mq-tablet) {
      margin-right: 34px;
      margin-bottom: 0;
    }
  }

  .CirclesList-text {
    text-align: center;

    @media('min-width: 700px') and ('max-width: 949px') {
      width: calc(70% - 34px);
    }
    @media('min-width: 950px') and ('max-width: 1099px') {
      width: calc(78% - 34px);
    }
    @media($mq-tablet) {
      text-align: left;
    }
  }

  .CirclesList-title {
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: rem-calc(22);
    @include hover-all;

    @media($mq-tablet) {
      margin-bottom: 12px;
      font-size: rem-calc(24);
    }
    @media($mq-desktop) {
      margin-bottom: 15px;
      font-size: rem-calc(26);
    }
  }

  .CirclesList-description {
    z-index: 1;
    position: relative;
    @include font-title;
    font-size: rem-calc(16);

    @media($mq-tablet) {
      font-size: rem-calc(18);
    }
    @media($mq-desktop) {
      font-size: rem-calc(19);
    }
  }
}
