// ==============
// News component
// ==============

.News-main {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
}


// ------
// Header
// ------

.News-headerTop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.News-type {
  font-size: rem-calc(18);
  text-transform: uppercase;
}

.News-date {
  color: $color-mediumGray;
  font-size: rem-calc(13.3);
}

.News-headerMain {
  @media($mq-desktop) {
    padding-right: 165px;
  }

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
}

.News-title {
  @include font-semibold;
  font-size: rem-calc(35);
  line-height: 1.1;

  @media($mq-from-tablet) {
    font-size: rem-calc(55);
  }
}

.News-subtitle {
  @include font-light;
  font-size: rem-calc(20);
  line-height: 1.1;

  @media($mq-from-tablet) {
    font-size: rem-calc(30);
  }
}

.News-dates {
  @include font-light;
  font-size: rem-calc(20);
  line-height: 1.1;

  @media($mq-from-tablet) {
    font-size: rem-calc(30);
  }
}

.News-intro {
  color: $color-darkGray;
  font-size: rem-calc(18);
  line-height: 1.1;

  .RichText p:not([class]) {
    @include font-base;
  }

  @media($mq-from-tablet) {
    font-size: rem-calc(26);
  }
}

.News-share {
  margin: 25px 0;

  @media($mq-from-tablet) {
    margin: 25px 0 40px;
  }
}


// -------
// Content
// -------

.News-content {
  & > *:not(:last-child) {
    margin-bottom: 60px;
  }
}
