// ================
// Homepage Novelty
// ================

.HomepageNovelty {
  @media($mq-tablet) {
    padding-top: 20px;
  }
  @media($mq-desktop) {
    padding-top: 25px;
  }

  .Wrapper {
    @media('max-width: 900px') {
      padding-top: 35px !important;
    }
  }
}

.HomepageNovelty .Title:last-child .Button {
  @media($mq-phone) {
    margin-left: -12px;
  }
}