// =============
// Articles List
// =============

.ArticlesList {
  //There is an html markup difference between article-list on Jeunesse and BD. That's the reason why a new css file is here. Please check ticket GALRUN-36
  li.SearchResults-show {
    flex:0 1 100%;
    max-width:100%;
  }
}