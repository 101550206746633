// ============
// GALLIMARD BD
// ============

// Gallimard BD theme
$theme: "bd";

// Fonts
$font-sans-name: 'Poppins';
$font-serif-name: 'Cocomat Pro';

// Unused : light, light-italic, medium-italic, semi-bold, semi-bold-italic, bold-italic
$font-sans-map: (
  'poppins/poppins-regular': 400 normal,
  'poppins/poppins-italic': 400 italic,
  'poppins/poppins-medium': 500 normal,
  'poppins/poppins-bold': 700 normal
);

$font-serif-map: (
  'cocomat-pro/cocomat-pro-regular': 400 normal,
  'cocomat-pro/cocomat-pro-bold': 700 normal,
  'cocomat-pro/cocomat-pro-extrabold': 800 normal
);

// Main styles
@import "main";

// Heart icon
.icon-heart { color: #FD3729; }

// Colors
$color-dark:            #313D4F;
$color-darkPrimary:     #313D4F;
$color-darkGray:        rgba($color-dark, .5);
$color-gray:            #BBBDC0;
$color-lightGray:       #F3F3F4;

$color-blue:            #313D4F;
$color-lightBlue:       #6E7B8F;
//$color-lightGreige:     #F4ECE1;
//$color-greige:          #D0C3AC;
$color-lightGreige:     #F8F6F1;
$color-greige:          #E4DBD0;
$color-red:             #EF4B53;
$color-redClear:        #EA5965;
$color-green:           #38D7BE;
$color-darkGreen:       #0EB79E;
$color-yellow:          #FEC018;


@import "../components/jeunesse/articles-list/articles-list";
@import "../components/jeunesse/book-attachment/book-attachment";
@import "../components/jeunesse/book-flip/book-flip";
@import "../components/jeunesse/books-filters/books-filters";
@import "../components/bd/category/category";
@import "../components/jeunesse/character/character";
@import "../components/jeunesse/filters/filters";
@import "../components/jeunesse/hero/hero";
@import "../components/jeunesse/modal/modal";
@import "../components/jeunesse/news/news";
@import "../components/jeunesse/news-embed/news-embed";
@import "../components/jeunesse/news-slider/news-slider";
@import "../components/jeunesse/richtext/richtext";
@import "../components/jeunesse/show-more/show-more";
@import "../components/jeunesse/videos/videos";
@import "../components/jeunesse/websites-list/websites-list";
@import "../components/futuropolis/contributors-list/contributors-list";

// ======== END TO UPDATE ================


// Other components
@import "../components/bd/articles-list/articles-list";
@import "../components/bd/book/book";
@import "../components/bd/book-item/book-item";
@import "../components/bd/books-list/books-list";
@import "../components/bd/books-slider/books-slider";
@import "../components/bd/breadcrumbs/breadcrumbs";
@import "../components/bd/button/button";
@import "../components/bd/character/character";
@import "../components/bd/circles-list/circles-list";
@import "../components/bd/cookie-banner/cookie-banner";
@import "../components/bd/contributor/contributor";
@import "../components/bd/collection/collection";
@import "../components/bd/contributors-list/contributors-list";
@import "../components/bd/filters/filters";
@import "../components/bd/footer/footer";
@import "../components/bd/header/header";
@import "../components/bd/header/header-search";
@import "../components/bd/hero/hero";
@import "../components/bd/homepage/homepage";
@import "../components/bd/homepage/homepage-editor/homepage-editor";
@import "../components/bd/homepage/homepage-networks/homepage-networks";
@import "../components/bd/homepage/homepage-news/homepage-news";
@import "../components/bd/homepage/homepage-novelty/homepage-novelty";
@import "../components/bd/homepage/homepage-prepublications/homepage-prepublications";
@import "../components/bd/homepage/homepage-series/homepage-series";
@import "../components/bd/homepage/homepage-slider/homepage-slider";
@import "../components/bd/menu/menu";
@import "../components/bd/news/news";
@import "../components/bd/news-embed/news-embed";
@import "../components/bd/news-item/news-item";
@import "../components/bd/news-slider/news-slider";
@import "../components/bd/newsletter/newsletter";
//@import "../components/bd/page/page";
@import "../components/bd/richtext/richtext";
@import "../components/bd/search-facets/search-facets";
@import "../components/bd/search-panel/search-panel";
@import "../components/bd/search-results/search-results";
@import "../components/bd/section/section";
@import "../components/bd/popup/popup";
@import "../components/bd/select-dropdown/select-dropdown";
@import "../components/bd/slider/slider";
@import "../components/bd/social-networks/social-networks";
@import "../components/bd/read-more/read-more";
@import "../components/bd/title/title";
@import "../components/bd/wishlist/wishlist";
@import "../components/main/pdf/pdf";
