// =====================
// Breadcrumbs component
// =====================

.Breadcrumbs {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-color: $color-white;
  transition: opacity .25s ease-in-out .1s;
  z-index: 9;
  // Style variant
  .Page.Category &,
  .Page.Contributor &,
  .Page.Collection & {
    position: relative;
  }

  @media($mq-phone) {
    z-index: 1;
  }

  @media print {
    display: none;
  }

  .Wrapper {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ul {
    display: flex;
    align-items: center;
    width: calc(100% + 4px);
    margin: 0 -2px;

    @media($mq-phone) {
      width: calc(100% + 40px);
      margin: 0 -20px;
      padding: 0 20px;
      overflow-x: auto;
    }
  }

  li {
    display: flex;
    align-items: center;
    padding-top: 3px;
    z-index: 1;
    &:not(:last-child) a:hover,
    &:not(:last-child) a:focus {
      text-decoration-color: $color-darkGray;
    }
    &:last-child a {
      pointer-events: none;
    }

    .icon {
      width: 5px;
      height: 10px;
      margin: -1px 5px 1px 6px;
    }
  }

  a {
    padding: 5px 2px;
    color: inherit;
    font-size: .65rem;
    text-decoration: underline;
    text-decoration-color: transparent;
    white-space: nowrap;
    transition: text-decoration-color .25s ease-in-out;
  }
}
