// =============
// Homepage News
// =============

.HomepageEditor {
  margin-bottom: 30px;
  text-align: center;
}

.HomepageEditor-title {
  text-align: center;
  margin-bottom: 10px;
}
