.WishList-main {
  min-height: 75vh;
  position: relative;

  .SearchResults-headerLeft .Title {
    color: $color-green;
  }

}

.WishList {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .Button--secondary {
    display: block;
  }


  .BookItem-deleteButton {
    margin-top: 15px;
    margin-bottom: 20px;
    text-align: left;
    z-index: 2;
    position: relative;
    background-color: transparent;
    border: 0;
    padding-left: 0;
    width: auto;
    @media print {
      display: none !important;
    }

    .Button + & {
      margin-top: -5px;
    }


    button {
    display: flex;
    align-items: center;
       span  {
         height: 16px;
         position: relative;
         top: 1px;
         display: flex;
         justify-content: center;
         align-items: center;
       }
    }
  }
  
  .BookItem-ean{
    display : block;
  }
}

.WishList-bookList {
  padding: 20px 0 0;
  flex: 1;

  @media($mq-tablet) {
    padding: 30px 0 0;
  }
  @media($mq-desktop) {
    padding: 40px 0 0;
  }
  @media print {
    padding: 10px 0 0;
  }
}

.WishList .SearchResults-header {
  @media($mq-upto-tablet) {
    padding-top: 50px;
  }
}

.WishList .SearchResults-headerInner {
  display: flex;
  align-items: flex-start;
  button {
    margin-left: 20px;
  }

  @media ($mq-phone) {
    flex-direction: column;
    margin-bottom: 20px;
    button {
      margin-top: 10px;
    }
  }

  @media ($mq-tablet) {
    button:first-of-type {
      margin-left: auto;
    }
  }

  @media ($mq-desktop) {
    button {
    display: none;
    }
  }
}

// --------------
// WishList Modal
// --------------

.MessageModal.email-wishlist-modal {
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  margin: 0;

  @media($mq-from-tablet) {
    width: 80%;
    margin: 20px auto;
  }

  @media($mq-phone) {
    top: 0 !important;
  }
}

#btn-email-wishlist {
  @media($mq-phone) {
    margin-top: 20px;
  }
}

.MessageModal.email-wishlist-modal {
  form {
    width: 100%;
    max-height: 100%;
    height: auto;
    padding: 20px;
    overflow-y: auto;

    @media($mq-from-tablet) {
      max-height: calc(100vh - 200px);
    }

    div {
      margin-bottom: 20px;
    }
  }

  #wishlist_email_subject {
    width: 100%;
  }
}

@media print {
	.WishList{
		.BooksList{
			margin-top: 0;
		}
	}
  .sf-display-none {
    display: none !important;
  }
}
