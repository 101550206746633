// ========================
// Homepage Prepublications
// ========================

.HomepagePrepublication-container {
  position: relative;
  display: block;
  margin: 70px 0;
  padding: 30px;
  background-color: $color-primary;
  color: $color-white;
  min-height: 500px;
  height: 100%;

  @media($mq-from-tablet) {
    margin: 80px 0;
  }
  @media($mq-tablet) {
    min-height: 400px;
    padding: 50px;
  }
  @media($mq-desktop) {
    padding: 70px;
  }

  & + & {
    margin-top: 90px;

    @media($mq-from-tablet) {
      margin-top: 110px;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }

  img {
    position: absolute;
    width: 250px;
    height: 338px;
    top: -30px;
    left: 30px;

    @media($mq-from-tablet) {
      top: -50px;
      right: 50px;
      left: auto;
    }
    @media($mq-tablet) {
      width: 296px;
      height: 400px;
    }
    @media($mq-desktop) {
      width: 370px;
      height: 500px;
    }
  }
}

.HomepagePrepublication-content {
  @media($mq-phone) {
    margin-top: 310px;
  }
  @media($mq-tablet) {
    width: calc(100% - 348px);
  }
  @media($mq-desktop) {
    width: calc(100% - 460px);
  }

  .Button {
    pointer-events: none;
  }
}

.HomepagePrepublication-title {
  display: block;
  margin-bottom: 18px;
  @include hover-all;
  @include font-title;
  font-size: rem-calc(28);
  line-height: 1.2;

  @media($mq-from-tablet) {
    margin-bottom: 30px;
  }
  @media($mq-tablet) {
    font-size: rem-calc(37);
  }
  @media($mq-desktop) {
    font-size: rem-calc(45);
  }

  &:hover, #{$keyboard} &:focus {
    & ~ .Button {
      color: $color-primary;
      padding: 3px 8px 5px;

      &::after {
        height: 100%;
        transition: height .25s ease-in-out;
      }
    }
  }
}

.HomepagePrepublication-text {
  display: -webkit-box;
  margin-bottom: 15px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: rem-calc(16);
  overflow: hidden;

  @media($mq-from-tablet) {
    margin-bottom: 20px;
    font-size: rem-calc(18);
  }
  @media($mq-upto-tablet) {
    -webkit-line-clamp: 3;
  }
  @media($mq-desktop) {
    -webkit-line-clamp: 7;
  }
}