// =============
// Homepage News
// =============

.HomepageNews-item {
  position: relative;
  display: flex;
  width: 100%;
  padding: 20px;

  @media('max-width: 949px') {
    flex-direction: column-reverse;
  }
  @media($mq-tablet) {
    padding: 25px 50px 45px;
  }
  @media($mq-desktop) {
    padding: 30px 80px 50px;
  }
}

.HomepageNews-content {
  @media($mq-tablet) {
    margin-right: 70px;
  }
  @media($mq-desktop) {
    margin-right: 100px;
  }

  & > *:not(:last-child) {
    margin-bottom: 25px;
  }

  .Button {
    margin-top: -13px;
    margin-left: -12px;
  }
}

.HomepageNews-title {
  display: block;
  color: $color-dark;
  @include font-bold($font-family: $font-family-title, $text-transform: uppercase);
  font-size: rem-calc(34);
  line-height: 1.2;

  @media($mq-tablet) {
    font-size: rem-calc(38);
  }
  @media($mq-desktop) {
    font-size: rem-calc(42);
  }
}

.HomepageNews-text {
  @include font-title;
  line-height: 1.5;
  .RichText p:not([class]), .RichText p[class*=text] {
    font-size: 1.1875rem;

    @media ($mq-phone) {
      font-size: 1rem;
    }
  }
}

.HomepageNews-image {
  flex: 1 0 auto;

  @media('max-width: 949px') {
    margin-bottom: 30px;
  }
}
