// ============
// Books slider
// ============

.BooksSlider {
  @media($mq-desktop) {
    .Slider-container {
      margin: 0 -22px;
    }

    &:not(.Bd) {
      .Slider-wrapper {
        padding: 0 22px 12px;
      }
    }
  }

  li {
    &:before {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      left: -22px;
      right: -22px;
      bottom: 0;
      border: 3px solid transparent;
      transition: background-color .25s ease-in-out,
      border-color .25s ease-in-out;
    }
  }
}

.BooksSlider.Bd .Slider-slide {
  margin-left: 28px;
  margin-right: 28px;
}
