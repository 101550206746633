// =============
// Header Search
// =============

.Header-searchPanel {
  z-index: 20;
  position: absolute;
  width: 100% !important;
  top: 100% !important;
  left: 0 !important;
  background-color: $color-white;
  color: $color-dark;
  box-shadow: 0 5px 35px 1px rgba($color-dark, .2);

  // Hidden by default
  .Wrapper {
    display: none;
  }

  // Active effects
  &[aria-hidden="false"] {
    .Wrapper {
      max-height: calc(100vh - 72px);
      overflow-x: hidden;
      overflow-y: auto;

      @media($mq-desktop) {
        max-height: calc(100vh - 124px);
      }
    }
  }
}
