// ==============
// Book component
// ==============

.Book-main {
  position: relative;
  padding-top: 46px;

  &--padding {
    @media($mq-from-tablet) {
      padding-top: 46px;
    }
  }

  &.Section {
    background-color: $color-lightenGray;

    & > .Wrapper {
      display: flex;

      @media($mq-phone) {
        padding-top: 0;
      }

      @media($mq-from-tablet) {
        padding-top: 45px;
      }

      @media($mq-upto-tablet) {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}


// --------------
// Book left side
// --------------

.Book-leftSide {
  position: relative;
  max-width: 435px;
  width: 100%;

  @media($mq-phone) {
    padding: 15px 5px 0 0;
  }
  @media($mq-tablet) {
    padding-bottom: 25px;
  }
  @media($mq-desktop) {
    margin-right: 75px;
  }
}

.Book-labels {
  display: flex;
  justify-content: space-between;
  width: 100%;
  left: 0;
  top: -58px;
  color: $color-darkGray;
  font-size: rem-calc(13);

  &--single {
    top: -38px;
  }

  @media($mq-phone) {
    margin-top: -10px;
    padding: 0 0 15px;
  }
  @media($mq-from-tablet) {
    position: absolute;
  }
}

.Book-status {
  padding: 11px 18px 10px;
  border: 1px solid $color-gray;
  border-radius: 3px;
  color: $color-dark;
  @include font-bold($font-family: $font-family-title);
}

.Book-audiobook {
  display: flex;
  align-items: center;
  padding-top: 5px;
  color: $color-mediumGray;

  .icon {
    width: 37px;
    height: 28px;
    margin-top: -8px;
    margin-left: 10px;
    color: $color-darkGray;
  }
}

.Book-cover {
  z-index: 2;
  position: relative;
  margin-bottom: 35px;

  img {
    width: 100%;
  }
}

.Book-options {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin: 14px 0;
  }
}

.Book-leafButton {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 0;

  @media($mq-phone) {
    margin-bottom: 30px;
  }

  .Button {
    max-width: 190px;
    width: 100%;
  }

  .BookAttachment ~ & {
    @media($mq-from-tablet) {
      margin-top: 20px;
    }
  }
}

.Book-download {
  display: block;
  margin-top: -10px;
  text-align: center;

  @media($mq-upto-tablet) {
    display: none;
  }

  a {
    padding: 10px 10px 7px;
    font-size: rem-calc(13);
    @include font-medium;
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: text-decoration-color .25s ease-in-out;

    &:hover, #{$keyboard} &:focus {
      text-decoration-color: darken($color-gray, 20%);
    }
  }

  .BookAttachment ~ & {
    margin-top: 14px;
  }
  .Book-leafButton ~ & {
    margin-top: 0;
  }
}

.Book-share {
  margin: 0;

  @media($mq-upto-tablet) {
    display: none;
  }

  .SocialNetworks {
    justify-content: center;
  }
}


// ---------------
// Book right side
// Primary info
// ---------------

.Book-rightSide {
  width: 100%;
}

.Book-primaryInfo {
  margin-bottom: 30px;
}


// Header
.Book-infoHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 20px;
  border-bottom: 1px solid $color-gray;
}

.Book-contributors {
  font-size: rem-calc(18);

  @media($mq-phone) {
    flex: 1 1 auto;
    padding-top: 10px;
    padding-right: 15px;
  }

  p + p {
    margin-top: 1px;
  }

  a {
    text-decoration: underline;
    text-decoration-color: $color-darkGray;
    transition: color .25s ease-in-out,
                text-decoration-color .25s ease-in-out;

    &:hover, #{$keyboard} &:focus {
      color: $color-red;
      text-decoration-color: inherit;
    }
  }
}

.Book-contributor {
  @include font-extrabold($font-family: $font-family-title, $text-transform: uppercase);
}

.Book-ageContainer {
  @media($mq-from-tablet) {
    .Button--secondary {
      display: none;
    }
  }
  @media($mq-phone) {
    .Button--secondary {
      font-size: 0;

      .icon {
        margin: 0;
      }
    }
  }
}


// Main
.Book-infoMain {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 12px;
  align-items: flex-start;

  & > div {
    flex: 1;
    padding-right: 35px;
    font-size: rem-calc(18);

    & > * {
      margin-bottom: 6px;
    }
  }

  @media($mq-phone) {
    .Button {
      display: none;
    }
  }
}

.Book-suptitle {
  @include font-title($text-transform: uppercase);
}

.Book-title {
  color: $color-darkGreen;
  @include font-extrabold($font-family: $font-family-title);
  font-size: rem-calc(47);
  line-height: 1.1;
}

.Book-subtitle {
  color: $color-darkGreen;
  @include font-extrabold($font-family: $font-family-title);
  font-size: rem-calc(27);
  line-height: 1.1;
}

//.Book-tomeTitle {
//
//}

.Book-comments {
  color: $color-lightBlue;
  font-size: rem-calc(13);
  line-height: 1.5;
}


// Resume
.Book-infoResume {
  padding-top: 12px;
  padding-bottom: 20px;
}

.Book-resume {
  margin-bottom: 15px;
  overflow: hidden;
  @include font-title;
  font-size: rem-calc(19);
  line-height: 1.3;
  transition: height .5s ease-out;
}

.Book-resumeButton {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: -20px;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 60px;
    bottom: 100%;
  }

  &:not(.Truncate-unexpandButton)::before {
    background: linear-gradient(to bottom, rgba($color-lightGreige, 0), $color-lightGreige);
  }

  &:hover span, &:focus span {
    color: $color-red;
  }

  &:focus {
    outline: 0;
  }

  span {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    color: $color-darkGray;
    font-size: rem-calc(13.3);
    pointer-events: auto;
    transition: color .25s ease-in-out;
  }

  .icon {
    width: 40px;
    height: 40px;
    margin-left: 2px;
    color: $color-red;
  }
}


// Footer
.Book-infoFooter {
  border-top: 1px solid $color-gray;
  @include font-title;

  li {
    padding: 10px 0 7px;
    border-bottom: 1px solid $color-gray;
    font-size: rem-calc(15);
  }
  strong {
    @include font-bold($font-family: $font-family-title);
  }
  a {
    transition: color .25s ease-in-out,
                text-decoration-color .25s ease-in-out;
  }
}

.Book-thematics a {
  text-decoration: underline;
  text-decoration-color: darken($color-gray, 20%);

  &:hover, #{$keyboard} &:focus {
    color: $color-green;
    text-decoration-color: inherit;
  }
}

.Book-categories a {
  text-decoration: underline;
  text-decoration-color: darken($color-gray, 20%);

  &:hover, #{$keyboard} &:focus {
    color: $color-red;
    text-decoration-color: inherit;
  }
}

.Book-recommendation a {
  color: $color-red;
}

.Book-teaching {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 15px;
  padding: 11px 14px;
  color: $color-dark;
  background-color: $color-white;
  border: 1px solid $color-gray;
  border-radius: 50px;
  font-size: rem-calc(15);

  @media($mq-phone) {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 15px;
    border-radius: 30px;
  }

  .icon-gallimard-teaching {
    width: 131px;
    height: 45px;
    margin-right: 14px;
    padding-left: 5px;
    padding-right: 14px;

    @media($mq-phone) {
      order: -2;
      margin-right: 5px;
    }
    @media($mq-from-tablet) {
      border-right: 1px solid $color-mediumGray;
    }
  }

  p {
    @include font-title;

    @media($mq-phone) {
      padding: 13px 5px 3px;
      font-size: rem-calc(14);
    }
    @media($mq-from-tablet) {
      flex: 1;
    }
    // Font-size exception
    @media('min-width: 1100px') and ('max-width: 1150px') {
      font-size: rem-calc(12.9);
    }
  }

  a {
    @include hover-all;
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-left: 22px;
    background-color: $color-lightGreige;
    border: 1px solid $color-gray;
    border-radius: 50%;
    transition: background-color .25s ease-in-out,
                border-color .25s ease-in-out,
                color .25s ease-in-out;

    &:hover, #{$keyboard} &:focus {
      background-color: $color-dark;
      border-color: $color-dark;
      color: $color-white;
    }

    @media($mq-phone) {
      order: -1;
      margin-left: 0;
    }

    .icon {
      width: 20px;
      height: 20px;
      margin: 10px;
    }
  }
}


// ---------------
// Book right side
// Secondary info
// ---------------

// Required for Foundation's Accordion init
.Book-secondaryInfo .accordion-content {
  display: none;
}


// Book buy
.Book-buy > ul {
  display: flex;
  margin: 0 -17px;
  padding: 15px 47px 20px 0;

  @media($mq-phone) {
    flex-direction: column;
  }

  & > li {
    padding: 0 17px;

    @media($mq-phone) {
      max-width: 400px;
      width: 100%;

      & + li {
        margin-top: 25px;
      }
    }
    @media($mq-from-tablet) {
      width: 50%;
      max-width: 400px;
    }

    a {
      text-decoration: underline;
      text-decoration-color: transparent;
      transition: text-decoration-color .25s ease-in-out;

      &:hover, #{$keyboard} &:focus {
        text-decoration-color: $color-darkGray;
      }
    }
  }
}

.Book-price {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  @include font-bold($font-family: $font-family-title);
}


// Book awards
.Book-awards {
  .accordion-content > ul {
    padding: 15px 47px 20px 0;
    @include font-title;

    li {
      position: relative;
      padding-left: 18px;
      padding-bottom: 5px;
      font-size: rem-calc(18);
      line-height: 1.2;

      &::before {
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        left: 4px;
        top: 8px;
        border-radius: 50%;
        background-color: $color-dark;
      }
    }
  }
}


// Book details
.Book-details {
  .accordion-content > ul {
    display: flex;
    padding: 15px 47px 20px 0;

    @media($mq-phone) {
      flex-direction: column;
    }

    & > li {
      flex: 1;

      &:first-child {
        padding-right: 20px;
      }
      &:last-child {
        padding-left: 20px;
      }
    }

    p {
      color: $color-darkGray;
      @include font-title;

      strong {
        color: $color-dark;
        @include font-bold($font-family: $font-family-title);
      }
    }

    a strong {
      @include text-underline;
      text-decoration: underline;
    }

    a:hover strong, #{$keyboard} a:focus strong {
      color: $color-red;
    }
  }
}

.Book-detailsSet {
  margin-bottom: 10px;

  &--main {
    span:not(:last-child)::after {
      content: '-';
      padding-left: 5px;
      padding-right: 1px;
    }
  }
  &:not(.Book-detailsSet--main) p {
    line-height: 1.35;
  }
}

// Phone variant
.Book-phoneInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-left: -10px;

  @media($mq-upto-tablet) {
    .Book-download {
      display: block;
      a {
        text-decoration: underline;
        text-decoration-color: darken($color-gray, 20%);
      }
    }
    .Book-share {
      display: flex;
      width: auto;
      margin-top: 6px;
    }
  }
  @media($mq-desktop) {
    display: none;
  }
}

// Detailed Description
.Book-detailedDescription {
  border-top: 1px solid $color-gray;

  .Wrapper {
    padding-top: 45px !important;
  }
}


// ----
// Leaf
// ----

.Book-leaf .Wrapper {
  z-index: 1;
  position: relative;
  width: 100%;
}

.Book-leafContainer {
  margin-top: 50px;
  margin-bottom: 10px;
  border: 1px solid $color-lightGray;
}

.Book-leafBox {
  max-width: calc(1200px - 2px);
  width: 100%;
  height: 650px;
  background-color: $color-white;

  iframe {
    width: 100% !important;
    height: 100% !important;
    border: none;
  }
}


// -----
// Press
// -----

.Book-press {
  overflow: hidden;

  .Wrapper {
    position: relative;

    .icon-quote {
      position: absolute;
      width: 210px;
      height: 185px;
      top: 70px;
      left: 20px;
      color: $color-white;
    }
  }
}

.Book-pressContainer {
  position: relative;
  margin-top: 50px;
  margin-bottom: 10px;
  text-align: center;
  @include font-title;
  font-size: rem-calc(24);
  transition: height .5s ease-out;
  overflow-y: hidden;
}

.Book-pressButton {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: -45px;
  margin-bottom: -45px;
  padding-top: 45px;
  padding-bottom: 55px;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 60px;
    bottom: 99%;
  }

  &:hover, &:focus {
    outline: 0;
  }

  button {
    &:hover, &:focus {
      outline: 0;
    }
  }

  &:not(.Truncate-unexpandButton)::before
  {
    background: linear-gradient(to bottom, rgba($color-lightGreige, 0), $color-lightGreige);
  }
}


.Book-infoResume .Book-pressButton {
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  justify-content: flex-end;
}

// ------
// Videos
// ------

.Book-videos .RichText {
  margin-top: 45px;
  color: $color-dark;




  h3 {
    font-size: rem-calc(26);
  }
  p {
    font-size: rem-calc(22);
  }
}
.Book-videos .ezrichtext-field.RichText.multipleVideos .RichText {
  margin-top: 20px !important;
  h2, h3, h4, h5 {
    color: $color-darkPrimary !important;
  }
}
