// ================
// Button Component
// ================


.Button {
  display: inline-table;
  padding: 11px 10px 10px;
  font-size: .7rem;
  text-align: center;
}


// --------------
// Button primary
// --------------

.Button--primary {
  position: relative;
  padding: 3px 0 5px;
  color: $color-dark;
  font-size: rem-calc(14);
  @include font-medium($font-family: $font-family-base);
  transition: color .25s ease-in-out;

  @media($mq-tablet) {
    font-size: rem-calc(16);
  }
  @media($mq-desktop) {
    font-size: rem-calc(18);
  }

  span {
    z-index: 1;
    position: relative;
  }

  &:hover, #{$keyboard} &:focus {
    color: $color-red;

    &::after {
      background-color: $color-red;
    }
  }

  &:not(.Button--icon) {
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      bottom: 3px;
      background-color: $color-darkPrimary;
      pointer-events: none;
      transition: background-color .25s ease-in-out;
    }
  }

  // Padding
  &.Button--padding {
    padding: 12px 12px 9px;

    &::after {
      width: calc(100% - 24px);
      margin: 0 12px 4px;
    }
  }

  // Icon
  &.Button--icon {
    display: inline-flex;
    align-items: center;

    .icon {
      margin-left: 14px;
    }
  }

  // Color
  &.Button--dark {
    color: $color-dark;

    &::after {
      background-color: $color-dark;
    }

    &.Button--active,
    &:hover, #{$keyboard} &:focus {
      color: $color-red;

      &::after {
        background-color: $color-red;
      }
    }
  }
  &.Button--green {
    color: $color-darkGreen;

    &::after {
      background-color: $color-darkGreen;
    }

    &.Button--active,
    &:hover, #{$keyboard} &:focus {
      color: $color-red;

      &::after {
        background-color: $color-red;
      }
    }
  }
  &.Button--red {
    color: $color-red;

    &::after {
      background-color: $color-red;
    }

    &.Button--active,
    &:hover, #{$keyboard} &:focus {
      color: $color-dark;

      &::after {
        background-color: $color-dark;
      }
    }

    // Hover color variant
    &.Button--toGreen {
      &.Button--active,
      &:hover, #{$keyboard} &:focus {
        color: $color-darkGreen;

        &::after {
          background-color: $color-darkGreen;
        }
      }
    }
  }
  &.Button--white {
    color: $color-white;

    &::after {
      background-color: $color-white;
    }

    &.Button--active,
    &:hover, #{$keyboard} &:focus {
      color: $color-yellow;

      &::after {
        background-color: $color-yellow;
      }
    }
  }

  // State
  &[disabled] {
    color: $color-gray;
    pointer-events: none;

    &::after {
      background-color: $color-gray;
    }
  }
}


// ----------------
// Button secondary
// ----------------

.Button--secondary {
  padding: 12px 12px 8px;
  border: 1px solid $color-darkGray;
  border-radius: 3px;
  color: $color-dark;
  font-size: rem-calc(15);
  @include font-medium;
  transition: background-color .25s ease-in-out,
              border-color .25s ease-in-out,
              color .25s ease-in-out;

  &.Button--active,
  &:hover, #{$keyboard} &:focus {
    background-color: $color-white;
    border-color: $color-dark;
  }

  // Button size
  &.Button--short {
    padding: 12px 12px 10px;
    font-size: rem-calc(12.5);
  }
  &.Button--large {
    min-width: 300px;
    padding: 12px 20px 10px;
    font-size: rem-calc(12.5);
  }

  // Button color
  &.Button--dark {
    background-color: $color-dark;
    border-color: $color-dark;
    color: $color-white;

    &.Button--active,
    &:hover, #{$keyboard} &:focus {
      background-color: $color-white;
      color: $color-dark;
    }
  }

  &.Button--toDark {
    background-color: transparent;
    border-color: $color-darkGray;

    &.Button--active,
    &:hover, #{$keyboard} &:focus {
      background-color: $color-dark;
      border-color: $color-dark;
      color: $color-white;
    }
  }

  // Button icon style
  &.Button--icon {

    // Add to wishlist button
    &.wishlisted {
      background-color: $color-red;
      border-color: $color-red;
      color: $color-white;

      .icon-heart {
        color: $color-white;
      }
    }

    .icon-heart {
      @media($mq-phone) {
        &:focus {
          color: #FD3729;
        }
      }
      @media($mq-upto-tablet) {
        &:hover, #{$keyboard} &:focus {
          color: #FD3729;
        }
      }
    }
  }

  &.Button--light {
    &.Button--active,
    &:not(.wishlisted):hover, #{$keyboard} &:focus {
      background-color: $color-white;
      border-color: $color-dark;
    }
  }

  .icon {
    width: 17px;
    height: 17px;
    margin-right: 10px;
  }

  // Button icon size
  &.Button--large.Button--icon {
    position: relative;

    .icon {
      position: absolute;
      margin-right: 0;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }
}


// Modal

.MessageModal[aria-hidden='false'] {
  display: flex !important;
  align-items: center;
  margin: 20vh auto 20px;
  height: 200px;
  flex-direction: column;
  justify-content: space-around;

  @media ($mq-phone) {
    width: calc(100% - 40px);
    min-height: 200px;
    margin: 20vh 20px 20px;
  }
}

.MessageModal a {
  text-decoration: underline;
}

.MessageModal p {
  font-weight: bold;
}

// -------------
// Other options
// -------------

.Button--noHover {
  &:hover, #{$keyboard} &:focus {
    color: inherit;
    border-color: inherit;
    background-color: inherit;
  }
}

.Button--noReaction {
  pointer-events: none;
}
