// =========
// Book item
// =========

.BookItem {
  position: relative;
  margin-top: 28px;
  
  &:not(.wishlist) {
    margin-bottom: 10px;
  }
  
  @media($mq-from-tablet) {
    margin-top: 35px;
  }
  
  @media print {
    margin-right: 20px;
  }
}

.BookItem-container {
  @media($mq-desktop) {
    &:hover, #{$keyboard} &:focus {
      .BookItem-cover img {
        opacity: .8;
      }
      .BookItem-subtext,
      .BookItem-title {
        color: $color-darkGreen;
      }
    }
  }
}

// -------
// Content
// -------

.BookItem-status .Button.Button--short {
  position: absolute;
  padding: 10px 12px 7px;
  top: -45px;
  left: 0;
  border: 1px solid $color-gray;
  
  span {
    @include font-bold($font-family: $font-family-title);
    font-size: rem-calc(12.5);
  }
}

.BookItem-cover {
  position: relative;
  //background-color: transparent;
  //transition: background-color .25s ease-in-out;
  
  img { // height: 294px;
    margin: 0 auto;
    object-fit: contain;
    transition: opacity .25s ease-in-out;
  }
}

.BookItem-text {
  padding: 12px 0;
  
  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
  
  .Button {
    z-index: 2;
    position: relative;
    margin-left: -12px;
    transform: scale(.75);
  }
}

.BookItem-subtext {
  color: $color-lightBlue;
  @include font-bold($font-family: $font-family-title, $text-transform: uppercase);
  font-size: rem-calc(15);
  transition: color .25s ease-in-out;
  
  & > *:not(:last-child)::after {
    content: ' - ';
  }
}

.BookItem-contributor {
  @media($mq-phone) {
    font-size: rem-calc(14);
  }
  
  & + & {
    margin-top: -5px;
  }
  
  a {
    z-index: 2;
    position: relative;
    padding: 5px 0;
    color: $color-dark;
    @include font-title;
    @include text-underline;
  }
}

.BookItem-title {
  @include font-bold($font-family: $font-family-title, $text-transform: uppercase);
  color: $color-darkPrimary;
  font-size: rem-calc(23);
  line-height: 1.2;
  
  a {
    @include hover-all;
    transition: color .25s ease-in-out;
  }
  @media print {
    font-size: rem-calc(13);
  }
}

.print{
  .BookItem-title {
    font-size: rem-calc(13);
  }
  .BookItem-subtext {
    font-size: rem-calc(11);
  }
}

.BookItem-price {
  @include font-medium;
  font-size: rem-calc(14);
}

.BookItem-ean{
  display : none;
}
