// ============
// Circles List
// ============

.CirclesList {
  position: relative;
  z-index: 0;
}

.CirclesList-list {
  & > li {
    position: relative;
    width: 70%;

    @media($mq-desktop) {
      width: 20.3%;
    }
    @media('min-width: 900px') and ('max-width: 1100px') {
      width: 25%;
    }
    @media('min-width: 700px') and ('max-width: 900px') {
      width: 35%;
    }
    @media('min-width: 500px') and ('max-width: 700px') {
      width: 45%;
    }
    @media($mq-phone) {
      margin: 0 auto;
    }
  }
}

.CirclesList-slider .Slider-container {
  overflow: visible;
}

.CirclesList-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  //margin: 15px 17px 30px;

  @media($mq-phone) {
    &:focus {
      .CirclesList-image::after {
        opacity: 1;
      }
      .CirclesList-image img {
        opacity: .8;
      }
    }
  }
  @media($mq-from-tablet) {
    margin-top: 15px;
    margin-bottom: 30px;

    &:hover, #{$keyboard} &:focus {
      .CirclesList-image::after {
        opacity: 1;
      }
      .CirclesList-image img {
        opacity: .8;
      }
    }
  }
}

.CirclesList-title {
  @include hover-all;
  @include font-medium;
  font-size: rem-calc(18);
  text-align: center;
  transition: color .25s ease-in-out;

  @media($mq-from-tablet) {
    font-size: rem-calc(17);
  }
}

.CirclesList-image {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  padding-top: 100%;
  background-color: $color-lightGray;
  border-radius: 50%;
  transition: background-color .25s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    top: -2px;
    left: -2px;
    border: 4px solid transparent;
    border-radius: 50%;
    opacity: 0;
    transition: border .25s ease-in-out,
                opacity .25s ease-in-out;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    transition: opacity .25s ease-in-out;
  }

  //.Section[class^="Homepage"] .CirclesList-item:hover & {
  //  border-color: $color-red;
  //}
  //.Section:not([class^="Homepage"]) .CirclesList-item:hover & {
  //  border-color: $color-green;
  //}
}
