// ===============
// Title component
// ===============

.Title {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: $color-darkPrimary;
  line-height: 1.2;

  & > span {
    position: relative;
    display: inline-block;
    margin-bottom: -4px;
    padding-bottom: 2px;
  }

  // Primary
  &--primary {
    position: relative;
    margin-bottom: 20px;
    font-size: rem-calc(35);
    @include font-bold($font-family: $font-family-title, $text-transform: uppercase);

    @media($mq-tablet) {
      font-size: rem-calc(42);
      margin-bottom: 25px;
    }
    @media($mq-desktop) {
      font-size: rem-calc(48);
      margin-bottom: 30px;
    }

    .Button {
      @media($mq-from-tablet) {
        margin-right: -12px;
      }
    }
  }

  // Secondary
  &--secondary {
    display: flex;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 4px;
    border-bottom: 1px solid $color-dark;
    font-size: rem-calc(22);
    @include font-bold($font-family: $font-family-title);

    span::after {
      bottom: -1px;
      border-bottom: 2px solid;
    }
  }

  // Color variant
  &--red {
    color: $color-red;
  }
  &--green {
    color: $color-darkGreen;
  }
}


// ---------------
// Accordion title
// ---------------

.Title.accordion-title {
  align-items: center;
  justify-content: space-between;

  .icon {
    width: 17px;
    height: 17px;
    margin-left: 20px;
    margin-right: 10px;
    color: $color-dark;
    transform: rotate(-90deg);
  }
}
a.Title {
  cursor: pointer;
}


// -------------------
// Homepage link title
// -------------------

.Homepage .Section .Title--primary:not(:first-child) {
  @media($mq-phone) {
    margin-bottom: 0;
  }
  @media($mq-from-tablet) {
    display: none;
  }
}
.Homepage .Section .Title--primary:not(:last-child) a {
  @media($mq-phone) {
    display: none;
  }
}