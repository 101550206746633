// =======
// Filters
// =======

.Filters {
  background-color: inherit;

  &::after {
    background-color: inherit;
  }
}

.Filters-header {
  @include font-bold($font-family: $font-family-title);
  border-bottom: 1px solid $color-dark;
}

.Filters-filterTitle {
  @include font-title;
}