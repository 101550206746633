// ========
// RichText
// ========

.RichText {

  .align-center {
    text-align: center;
  }

  // titles
  h1, h2, h3, h4, h5, h6 {
    &:not([class]),
    &[class*="text"] {
      position: relative;
      margin-bottom: 1rem;
      color: $color-dark;
      @include font-bold;
      font-size: rem-calc(28);
      line-height: 1.1;

      @media ($mq-tablet) {
        font-size: rem-calc(34);
      }
      @media ($mq-desktop) {
        font-size: rem-calc(40);
      }
    }
    &:not(:first-child) {
      line-height: 1.5;
      margin-top: 1.3em;
    }
  }
  figure[class*="object"],
  a[name]:first-child {
    + h1, + h2, + h3, + h4, + h5, + h6 {
      margin-top: 0 !important;
    }
  }
  h2:not([class]),
  h2[class*="text"] {
    clear: both;
    font-size: rem-calc(28);

    @media ($mq-tablet) {
      font-size: rem-calc(34);
    }
    @media ($mq-desktop) {
      font-size: rem-calc(40);
    }
  }
  h3:not([class]),
  h3[class*="text"] {
    font-size: rem-calc(20);

    @media ($mq-from-tablet) {
      font-size: rem-calc(28);
    }
  }
  h4:not([class]),
  h4[class*="text"] {
    font-size: rem-calc(16);

    @media ($mq-from-tablet) {
      font-size: rem-calc(20);
    }
  }
  h5:not([class]),
  h5[class*="text"] {
    margin-bottom: 0.5rem;
    font-size: rem-calc(14);

    @media ($mq-from-tablet) {
      font-size: rem-calc(16);
    }
  }
  h6:not([class]),
  h6[class*="text"] {
    margin-bottom: 0.5rem;
    font-size: rem-calc(14);
  }

  // paragraph
  p:not([class]),
  p[class*="text"] {
    margin-bottom: 1rem;
    @include font-title;
    font-size: rem-calc(22);

    .Hero-text & {
      @include font-base;
      font-size: rem-calc(18);
    }
  }

  strong {
    font-weight: 700;
  }

  // lists
  ul:not([class]),
  ol:not([class]) {
    margin-bottom: 1.2rem;
    padding: 0;
    text-align: initial;

    ul, ol {
      margin-top: 0.5rem;
      margin-bottom: 0;
      color: $color-darkGray;
    }

    li {
      overflow: hidden;
      position: relative;
      @include font-title;
      font-size: rem-calc(16);

      @media($mq-tablet) {
        font-size: rem-calc(18);
      }
      @media($mq-desktop) {
        font-size: rem-calc(22);
      }

      .Hero-text & {
        @include font-base;
        font-size: inherit;
      }
    }
  }

  ul:not([class]) {
    & > li {
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: .6em;
        left: 0;
        border: 2px solid $color-dark;
        border-radius: 50%;
      }

      ul {
        @media($mq-from-tablet) {
          margin-left: 30px;
        }
      }

      ul > li::before {
        border-color: $color-darkGray;
      }
    }
  }

  ol:not([class]) {
    counter-reset: listnum;

    & > li {
      padding-left: 30px;
      list-style-type: none;

      &::before {
        content: counters(listnum, ".") ".";
        counter-increment: listnum;
        margin-left: -30px;
        margin-right: 10px;
        font-weight: 700;
        @include font-title;
        font-size: rem-calc(16);

        @media($mq-tablet) {
          font-size: rem-calc(18);
        }
        @media($mq-desktop) {
          font-size: rem-calc(22);
        }
      }

      ol {
        @media($mq-from-tablet) {
          margin-left: 20px;
        }
      }

      ol > li {
        &::before {
          content: counters(listnum, ".") " ";
          color: inherit;
        }

        ol > li {
          padding-left: 30px;
        }
      }
    }
  }

  // link, btn, download
  a:not([class]),
  a[class=""],
  a[class*="link"],
  button[type="button"]:not([class]) {
    color: inherit;
    font-family: inherit;
    font-weight: 700;
    text-decoration: underline;
    text-decoration-color: inherit;
    transition: color .25s ease-in-out,
    text-decoration-color .25s ease-in-out;

    &:hover, #{$keyboard} &:focus {
      color: $color-red;
      text-decoration-color: $color-red;
    }
  }

  .Button, .button {
    margin-bottom: 0;
  }

  // blockquote
  blockquote {
    position: relative;
    margin: 2rem 0;
    font-style: italic;
    @include font-title;
    font-size: rem-calc(18);

    @media($mq-tablet) {
      font-size: rem-calc(20);
    }
    @media($mq-desktop) {
      font-size: rem-calc(24);
    }

    .Hero-text & {
      @include font-base;
    }

    > div {
      > p:first-child {
        &:first-child::before {
          content: '“';
        }
      }

      > p:last-child {
        margin-bottom: 0 !important;
        &:last-child::after {
          content: '”';
        }
      }
    }

    > p {
      margin-bottom: 0 !important;

      &:first-child::before {
        content: '“';
      }
      &:first-child::after {
        content: '”';
      }
    }

    strong,
    em {
      display: block;
      font-style: normal;
      line-height: 1.3;
    }
    strong {
      margin-top: .5rem;
      font-size: rem-calc(16);

      @media($mq-tablet) {
        font-size: rem-calc(18);
      }
      @media($mq-desktop) {
        font-size: rem-calc(20);
      }
    }
    em {
      color: $color-mediumGray;
      @include font-base;
      font-size: rem-calc(12);

      @media($mq-from-tablet) {
        font-size: rem-calc(14);
      }
    }
  }

  // images
  figure {
    margin-bottom: 2rem;

    @media($mq-phone) {
      text-align: center;
    }
  }

  figcaption {
    padding-top: 10px;
    color: $color-mediumGray;
    @include font-base;
    font-size: rem-calc(12);
    line-height: 1.6;

    @media($mq-from-tablet) {
      font-size: rem-calc(13.3);
    }
  }

  // table
  .tableoverlay {
    overflow-x: auto;

    table {
      min-width: 1200px;
    }
  }

  table {
    width: 100%;
    margin: 2em 0;
    border: 1px solid $color-white;
    border-collapse: collapse;
    line-height: 1.7;

    .Section--gray & {
      border: 1px solid $color-lightenGray;
    }

    caption {
      margin-bottom: 0.5rem;
      text-align: left;
      font-size: rem-calc(14);
      font-weight: 400;

      @media($mq-from-tablet) {
        font-size: rem-calc(16);
      }
    }

    thead tr, thead th {
      background-color: $color-dark;
      color: $color-white;
    }

    tbody tr {
      &:nth-child(even) {
        background-color: $color-lightenGray;

        .Section--gray & {
          background-color: rgba($color-lightGray, .5);
        }
      }
    }

    th {
      border: 0;
      padding: 25px;
      text-align: initial;
      font-weight: 400;
      color: $color-white;
      background-color: $color-dark;
      font-size: rem-calc(18);

      @media($mq-from-tablet) {
        font-size: rem-calc(20);
      }
    }

    td {
      padding: 25px;
      border: 1px solid $color-white;
      @include font-title;
      font-size: rem-calc(16);

      @media($mq-from-tablet) {
        font-size: rem-calc(18);
      }

      .Section--gray & {
        border: 1px solid $color-lightenGray;
      }
      .Hero-text & {
        @include font-base;
      }
    }

    p:not([class]) {
      margin-bottom: 0.5rem !important;
      font-size: rem-calc(16);

      @media($mq-from-tablet) {
        font-size: rem-calc(18);
      }
    }

    figure {
      margin-bottom: 0;
    }
  }

  .clear {
    clear: both;
    font-size: 0;
    height: 0;
  }

  // -----------------
  // eZ custom classes
  // -----------------

  // Image position
  .ez-embed-type-image {
    &.align-left {
      text-align: left;

      figure {
        margin-right: 2.75rem;

        @media($mq-from-tablet) {
          float: left;
        }
      }
    }

    &.align-center {
      text-align: center;
    }

    &.align-right {
      @media($mq-from-tablet) {
        text-align: right;
      }

      figure {
        margin-left: 2.75rem;

        @media($mq-from-tablet) {
          float: right;
        }
      }
    }
  }

  // Text type
  .small-text p {
    color: $color-mediumGray;
    @include font-base;
    font-size: rem-calc(12);
    line-height: 1.6;

    @media($mq-from-tablet) {
      font-size: rem-calc(13.3);
    }
  }

  // Text colors
  .red-text {
    color: $color-red;
  }
  .green-text {
    color: $color-green;
  }
  .yellow-text {
    color: $color-yellow;
  }
  .blue-text {
    color: $color-blue;
  }

  // Title levels
  .big-title {
    @include font-light;
    font-size: rem-calc(35);
    line-height: 1.1;

    @media($mq-tablet) {
      font-size: rem-calc(45);
    }
    @media($mq-desktop) {
      font-size: rem-calc(55);
    }
  }

  .bold-title {
    @include font-semibold;
    font-size: rem-calc(18);
    line-height: 1.2;

    @media($mq-tablet) {
      font-size: rem-calc(22);
    }
    @media($mq-desktop) {
      font-size: rem-calc(26);
    }
  }

  .thin-title {
    @include font-light;
    font-size: rem-calc(18);
    line-height: 1.2;

    @media($mq-tablet) {
      font-size: rem-calc(22);
    }
    @media($mq-desktop) {
      font-size: rem-calc(26);
    }
  }

  // Download button
  .download-button {
    @extend .Button;
    @extend .Button--secondary;
    @extend .Button--light;
    @extend .Button--icon;
  }

  // Video embed
  .video-embed {
    position: relative;
    padding-top: 56%;
    text-align: center;

    &.klaro-hidden-embed {
      padding-top: 0;
    }
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  // Other iframes
  iframe {
    border: none;
  }

  // Highlight
  .highlight-content {
    margin: 2em 0;
    padding: 1.75em 2em 1em;
    background-color: $color-lightenGray;
    border: 1px solid $color-lightGray;
    border-radius: 3px;

    .Section--gray & {
      background-color: rgba($color-lightGray, .5);
      border: 1px solid rgba($color-gray, .5);
    }

    @media($mq-from-tablet) {
      padding: 2.75em 3em 2em;
    }
  }

  .Book-leaf .Wrapper {
    padding-top: 0;
    padding-bottom: 0;
  }
}

// video-container


.video-container.multipleVideos {

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  @media ($mq-from-tablet) {
    flex-direction: row;
  }

  .video-embed {
    @media ($mq-phone) {
      display: table;
    }
  }

  .video-text.mobileOnly {
    margin: 10px 0;
  }

  .videoContainer-left {
    width: 100%;
    @media ($mq-from-tablet) {
      width: calc((100% - 20px) * 2 / 3);
    }
  }

  .videoContainer-right .video-embed {
    @media ($mq-from-tablet) {
      &:after {
        width: 100%;
      }
    }

    .hasContent {
      width: 50%;
      margin-left: 50%;
    }
  }


  .video-embed {
    @media ($mq-from-tablet) {
      &:after {
        content: "";
        border: 2px solid transparent;
        position: absolute;
        pointer-events: none;
        cursor: pointer;
        top: 0;
        left: 0;
        height: 100%;
        width: calc(100% + 2px);

      }

      &:hover:after {
        transition: border 0.25s ease-in-out;
        border: 3px solid $color-blue;
        z-index: 2;
      }
    }
  }
  .videoContainer-right {
    width: 100%;
    @media ($mq-from-tablet) {
      width: calc(100% * 1 / 3);
      display: flex;
      flex-direction: column;
      max-height: 441px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .video-embed {
      @media ($mq-from-tablet) {
        padding-top: calc((100% - 30px) * 1 / 3);
        height: calc((100% - 30px) * 1 / 3);
        max-height: calc((100% - 30px) * 1 / 3);
        &:after {
          border: 1px solid #C5C5C5;
        }
        &:hover:after {
          transition: border 0.25s ease-in-out;
          border: 3px solid $color-blue;
          z-index: 2;
        }
      }

      background-color: #fff;
      position: relative;

      &:not(:first-child) {
        margin-top: 15px;
      }

      .video-text + iframe {
        @media ($mq-from-tablet) {
          width: 50%;
          margin-left: 50%;
        }
      }

      .video-text {
        position: absolute;
        width: 50%;
        height: 100%;
        top: 0;
        left: 0;
        margin-right: 50%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          margin-bottom: 0;
          font-family: "Poppins", Arial, sans-serif;
          font-weight: 500;
          font-size: 16.79px;
        }
      }

      &:hover {
        color: $color-blue;

      }

    }

    + .Richtext {
      width: calc(100% - 2 / 3);
      margin-right: 20px;
      margin-top: 20px;
    }
  }

}


.Book-videos .multipleVideos .fromTabletOnly.RichText {
  margin-top: 15px;
  width: 100%;
}
