// ==========
// Hero style
// ==========

.Hero {
  .Wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-top: 0;

    @media($mq-upto-tablet) {
      flex-direction: column-reverse;
    }
    @media($mq-desktop) {
      align-items: center;
      padding-bottom: 0;
    }
  }
  .SocialNetworks {
    margin-top: 20px;
  }
}

// Style variant
.AlltipsHero .Wrapper,
.CategoryHero .Wrapper,
.TipsHero .Wrapper {
  @media($mq-desktop) {
    min-height: 490px;
  }
}

.CollectionHero .Wrapper,
.ContributorHero .Wrapper {
  @media($mq-upto-tablet) {
    flex-direction: column;
    padding-top: 45px;
  }
  @media($mq-desktop) {
    padding: 0 65px;
    align-items: flex-start;
  }
}

.CharacterHero .Wrapper {
  @media($mq-upto-tablet) {
    padding-top: 45px;
  }
  @media($mq-desktop) {
    padding-top: 0;
  }
}


// -----
// Intro
// -----

.Hero-intro {
  max-width: 750px;
  width: 100%;

  @media($mq-desktop) {
    margin: 45px 0;
  }

  & > *:not(:last-child) {
    margin-bottom: 15px;
  }

  &--half { // If image is defined
    max-width: none;

    @media($mq-desktop) {
      width: 50%;
      padding-right: 60px;
    }
  }
  &--right {
    @media($mq-desktop) {
      flex: 1;
    }
  }
  &--character {
    &.Hero-intro--half {
      @media($mq-desktop) {
        width: calc(100% - 490px);
        padding-right: 100px;
      }
    }
  }
}


// -------
// Content
// -------

.Hero-category {
  margin-top: 8px;
  color: $color-red;
  font-size: rem-calc(18);
  text-transform: uppercase;
}

.Hero-title {
  @include font-semibold;
  line-height: 1.1;

  &--medium {
    font-size: rem-calc(30);

    @media($mq-from-tablet) {
      font-size: rem-calc(40);
    }
  }
  &--large {
    font-size: rem-calc(35);

    @media($mq-from-tablet) {
      font-size: rem-calc(55);
    }
  }
}

.Hero-text {
  font-size: rem-calc(18);
  line-height: 1.8;
  overflow: hidden;
}

.Hero-textLight {
  @include font-light;
  font-size: rem-calc(15);
  line-height: 1.5;
}

.Hero-textUppercase {
  text-transform: uppercase;
}

.Hero-link {
  text-decoration: underline;
  text-decoration-color: $color-darkGray;
  transition: color .25s ease-in-out,
              text-decoration-color .25s ease-in-out;

  &:hover, #{$keyboard} &:focus {
    color: $color-red;
    text-decoration-color: $color-red;
  }

  .Hero-links & {
    margin-bottom: 5px;
  }
}


// -----
// Image
// -----

.Hero-image {

  &--left {
    margin: 45px 0;

    @media($mq-upto-tablet) {
      margin-top: 0;
    }
    @media($mq-desktop) {
      margin-right: 65px;
    }

    img {
      max-width: 330px;
    }
  }

  &--right {
    @media($mq-upto-tablet) {
      height: 260px;
      margin: 0 -20px 30px;
    }
    @media($mq-desktop) {
      position: absolute;
      width: calc(50% - 20px);
      min-height: 490px;
      height: 100%;
      right: 20px;

      img {
        position: absolute;
      }
    }
  }

  &--character {
    width: 65vw;
    height: 65vw;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 45px;
    border-radius: 50%;
    overflow: hidden;

    @media('min-width: 400px') and ('max-width: 700px') {
      width: 260px;
      height: 260px;
    }
    @media($mq-tablet) {
      width: 360px;
      height: 360px;
    }
    @media($mq-desktop) {
      width: 490px;
      height: 490px;
      margin: 0;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.Hero-imageCopyright {
  margin-top: 10px;
  color: $color-darkGray;
  font-size: rem-calc(14);
}
