// =======
// Filters
// =======

.Filters {
  position: relative;
  margin: -30px 0 30px;
  padding: 30px 0;
  background-color: $color-lightenGray;

  &::after {
    content: '';
    position: absolute;
    width: 100vw;
    height: 100%;
    background-color: $color-lightenGray;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  & > * {
    z-index: 1;
    position: relative;

    & > *:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .swiper-slide {
    width: auto;
  }

  .SelectDropdown {
    float: left;
    max-width: 265px;
    width: 100%;
  }

  .SearchResults-resetTags {
    float: right;
  }
}

.Filters-header {
  margin-bottom: 15px;
  border-bottom: 1px solid $color-lightGray;
  color: $color-dark;
  font-size: rem-calc(26);
  @include font-semibold;
}

.Filters-filter {
  display: flex;
  align-items: center;

  @media($mq-phone) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.Filters-filterTitle {
  flex: 1 0 auto;
  margin-right: 15px;
  color: $color-darkGray;
  font-size: rem-calc(26);

  @media($mq-phone) {
    margin-bottom: 5px;
  }
}

.Filters-filterList {
  width: 100%;

  .SearchResults-tagsContainer {
    position: relative;
    top: 0;

    .SearchResults-taglist ul {
      position: relative;
    }

  }
}

// Slider

.FiltersSlider {

  margin-left: auto;
  margin-right: auto;
  @media('max-width: 1399px') {
    .Slider-button {
      display: block;
    }

    .Slider-buttonNext,
    .Slider-buttonPrev {
      background-color: $color-lightenGray;
      width: auto;
    }
    .Slider-buttonPrev {
      left: -73px;
    }
    .Slider-buttonNext {
      right: -73px;
    }
  }
}

.FiltersSlider:not(.multipleRows) .Slider-button {
  top: 15px;
}

.FiltersSlider.multipleRows .Slider-button {
  bottom: 10px;
}

// filter kind

.FiltersSlider .Filters-filterKind ul {
  width: calc(100% - 60px);
  margin: auto;

}

.FiltersSlider .Filters-filterKind .Filters-filterList {
  position: relative;
}

.FiltersSlider .Filters-filterKind .Slider-button {
  top: 0;
  height: 100%;
  z-index: 9;
  background-color: $color-lightenGray;
}

.FiltersSlider .Filters-filterKind .Slider-buttonPrev {
  left: -30px;
}

.FiltersSlider .Filters-filterKind .Slider-buttonPrev .icon {
  top: -15px;
  position: relative;
  left: 10px;
}

.FiltersSlider .Filters-filterKind .Slider-buttonNext {
  right: -30px;

}

.FiltersSlider .Filters-filterKind .Slider-buttonNext .icon {
  top: -15px;
  position: relative;
  right: 10px;
}
