// ==========
// Hero style
// ==========

.Hero {
  color: $color-dark;
}

// Style variant
.CategoryHero .Wrapper {
  @media($mq-desktop) {
    min-height: 490px;
  }
}

.CollectionHero .Wrapper,
.ContributorHero .Wrapper {
  @media($mq-upto-tablet) {
    flex-direction: column;
    padding-top: 45px;
  }
  @media($mq-desktop) {
    padding: 0 65px;
    align-items: flex-start;
  }
}

.CharacterHero .Wrapper {
  @media($mq-upto-tablet) {
    padding-top: 45px;
  }
  @media($mq-desktop) {
    padding-top: 0;
  }
}


// -----
// Intro
// -----

.Hero-intro {
  .ContributorsListHero & {
    max-width: none
  }
}


// -------
// Content
// -------

.Hero-title {
  @include font-extrabold($font-family: $font-family-title);
}

.Hero-text {
  @include font-title;
}

.Hero-link {
  @include font-title;
}


// -----
// Image
// -----

.Hero-image {
  top: 0;
}

// Contributors List variant
.Hero-alphabet {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 28px -6px 0;

  @media($mq-from-tablet) {
    margin: 40px -10px 0;
  }
}

.Hero-letter {
  a, p {
    margin: 0 3px;
    padding: 3px;
    font-size: rem-calc(20);

    @media($mq-from-tablet) {
      margin: 0 5px;
      padding: 5px;
      font-size: rem-calc(22);
    }
  }
  a {
    @include font-bold($font-family: $font-family-title);
    @include text-underline($color: $color-darkGreen, $text-decoration-color: $color-darkGreen);
  }
  p {
    @include font-title;
    color: $color-darkGray;
  }
  a ~ p {
    display: none;
  }
}



// CONTRIBUTOR HERO

.Section .ContributorHero .Wrapper {
  @media ($mq-from-tablet) {
    padding-top: 20px;
  }
}
